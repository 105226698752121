<template>
  <div class="parent-modal">
    <div class="modal myModal fade" id="import-excell" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content category-content">
          <div class="modal-header category-header">
            <div class="d-flex justify-content-between mb-3" style="width:100%">
              <h3 class="modal-title">Excell import</h3>
              <button type="button" data-dismiss="modal" aria-label="Close" class="close">
              <span aria-hidden="true">
                <img src="../../../assets/icons/x.svg" alt="">
              </span>
              </button>
            </div>
            <p class="cashback-sub-title instruction" style="font-weight:normal ">Import instructions</p>
            <div class="download">
                <img class="mr-1" src="../../../assets/icons/download.svg">
                <a :href="exampleFileUrl" download>download example</a>
            </div>
          </div>

          <div class="modal-body category-body">
            <form @submit.prevent="uploadFile" class="dropzone dropzone-form text-center" id="myAwesomeDropzone" 
                  @dragover.prevent @drop.prevent="onDrop">
              <div @click="triggerFileInput" class="dropzone-content">
                <img class="uplimg" src="../../../assets/icons/uploadExcel.svg">
                <p>Нажмите или перетащите файл сюда</p>
              </div>
              <input class="drop-input" ref="fileInput" @change="onFileChange" name="file" type="file" accept=".xlsx"/>
              <div v-if="file" class="selected-file">
                Выбран файл: {{ file.name }}
              </div>
              <div class="errorExport" v-if="error">
                <img src="../../../assets/icons/exportError.svg"><br>
                <span style="color:#D81919">{{ error }}</span>
              </div>
            </form>

            <div class="d-flex justify-content-end">
              <button @click="uploadFile" class="save" :disabled="!file">Import</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "ImportExcell",

  data(){
    return{
      new_category: {
        name: '',
        type: 'product',
        parent: ''
      },

      currentData: {},
      isCategoryOpen: {},
      isChildOpen: {},
      selectedCategory: null,
      isCategoryVisible: false,
      selectedCategoryInput:'',
      file: null,
      error: null,
      exampleFileUrl: '/example_import_products.xlsx',
    }
  },

  methods:{

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    onDrop(event) {
      const file = event.dataTransfer.files[0];
      this.handleFile(file);
    },

    onFileChange(event) {
      const file = event.target.files[0];
      this.handleFile(file);
    },

    handleFile(file) {
      const valid = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
      this.error = null;

      if (file) {
        if (file.size > 10000000) {
          this.error = 'Размер файла превышает 10 МБ';
        } else if (!valid.includes(file.type)) {
          this.error = 'Неверный тип файла, разрешены только файлы .xlsx';
        } else {
          this.file = file;
        }
      }
    },

    uploadFile() {
      if (this.file) {
        const formData = new FormData();
        formData.append('file', this.file);

        this.axios.post(this.url('importExcellProducts'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          this.$successAlert('Файл успешно загружен')
          console.log('Файл успешно загружен', response);
          $('#import-excell').modal("hide");
        })
        .catch(error => {
          this.error = 'Ошибка при загрузке файла';
          console.error('Ошибка при загрузке файла', error);
        });
      } else {
        this.error = 'Пожалуйста, сначала выберите файл';
      }
    }
  },
  mounted(){
    // this.importClient()

  }
}
</script>

<style scoped>
.category-content{
  border:0;
  background: #FFFFFF;
  border-radius: 5px !important;
  width: 30rem;
  height: 400px;
}

.category-body{
  padding: 0 50px;
  padding-bottom: 34px;
}
.modal-content .modal-header{
  padding: 27px 30px;
  display: block;
}
.category-body{
  padding: 0 30px;
  padding-bottom: 30px;
}

.instruction{
  margin-bottom: 5px;
}
.download a,.download a:hover{
  color:#616CF5;
  text-decoration: none;
}
.dropzone-form{
  margin-bottom: 24px;
  min-height:inherit;
  border: 1px dashed #8C94A5;
  border-radius: 5px;
  height: 165px;
  position: relative;
  padding: 0;
}
.drop-input{
  display: none;
}
.dropzone img{
  margin-bottom: 5px;
  margin-top: 35px;
}
.errorExport{
  display: none;
}
.showfile{
  min-height: 20px !important;
}
.dropzone-content {
  cursor: pointer;
}
.selected-file {
  margin-top: 10px;
  font-size: 14px;
}
.errorExport {
  margin-top: 10px;
}
</style>
