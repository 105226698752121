<template>
  <div class="modal fade right "  id="edit-stories" tabindex="-1" role="dialog" aria-labelledby="edit-stories" aria-hidden="true">
      <div class="modal-dialog modal-full-height myModal-dialog mr-0 mt-0 mb-0 mr-0 h-100" style="max-width: calc(100% - 250px);" role="document" >
        <div class="modal-content myModal-content h-100">
          <div class="modal-header justify-content-start align-items-center">
            <button type="button" data-dismiss="modal" aria-label="Close" class="close">
              <span aria-hidden="true">
                <img src="../../assets/icons/xBlack.svg" alt="">
              </span>
            </button>
            <h3 class="modal-title">Edit stories</h3>
          </div>
          <div class=" myModal-body">
            <div class="row">
                <div class="col-lg-5  ">
                    <h3 class="stories-title mb-1">Cover Art</h3>
                    <p class="stories-description margin-bottom-20">You can upload image or video, the minimum resolution is<br> 1080*1920 px, the size is not more than 10 MB.</p>
                    <button v-if="!stories.video && !stories.img" class="upload-photo margin-bottom-30" @click="selectPhoto">+ Upload photo</button><br>        
                    <input class="d-none" type="file" name="" ref="uploadPhoto" @change="uploadPhoto($event)" accept="image/*">
                    <div class="image-preview position-relative" v-if="stories.img">
                        <img v-if="newImage" :src="imagePreview" alt="" class="img-new w-100 d-block" accept="image/png, image/jpeg">
                        <img v-else :src="makeImg(stories.img)" alt="" class="w-100 img-new d-block" accept="image/png, image/jpeg">
                        <button class="deleteImage" @click="clearInput('img')">
                            <img src="../../assets/icons/deleteClient.svg">
                        </button>
                    </div>

                    <!-- Button to Upload Video -->
                  <button v-if="!stories.video && !stories.img" class="upload-photo margin-bottom-30" @click="selectVideo">+ Upload video</button><br>        
                  <input class="d-none" type="file" name="" ref="uploadVideo" @change="uploadVideo($event)" accept="video/*">
                  
                  <!-- Video Preview -->
                  <div class="video-preview position-relative" v-if="stories.video">
                    <video v-if="newVideo" :src="videoPreview" class="video-new w-100 d-block" controls></video>
                    <video v-else :src="makeVideo(stories.video)" class="video-new w-100 d-block" controls></video>
                    <button class="deleteVideo" @click="clearInput('video')">
                      <img src="../../assets/icons/deleteClient.svg">
                    </button>
                  </div>
                    
                </div>
                <div class="col-lg-6 parent">
                    <label>Name</label>
                    <input v-model="stories.name" class="cashback-input margin-bottom-30" style="width:100%">

                    <div class="d-flex btns">
                        <button class="save mr-2" @click="onSubmit">Save</button>
                        <button data-dismiss="modal" aria-label="Close" class="cancel">Cancel</button>
                    </div>
                </div>  
            </div>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import $ from "jquery";
export default {
    name: "EditStories",
    props: ['selectedStories'],
    data(){
        return{
            stories:{
                name: '',
                img: '',
                video: ''
            },
            newImage: false,
            newVideo: false
        };
    },
    watch: {
        selectedStories: function (val) {
            this.stories = Object.assign({}, val);
            console.log("stories", this.stories)
            if (this.stories.category == "promotions"){
                this.stories.category = true
                this.stories.startDate = this.stories.startDate.slice(0, 10)
                this.stories.endDate = this.stories.endDate.slice(0, 10)
            }else{
                this.stories.startDate = ""
                this.stories.endDate = ""
                this.stories.category = false
            }
            this.newImage = false
        }
    },
    computed:{
        imagePreview() {
            if(this.stories.img){
                return URL.createObjectURL(this.stories.img)      
            }
            if(this.stories.video){
                return URL.createObjectURL(this.stories.video)
            }
            return null
        }
    },
    methods:{
    selectPhoto(){
        this.$refs.uploadPhoto.click();
    },
    selectVideo() {
      this.$refs.uploadVideo.click();
    },
    uploadPhoto(event) {
        var valid = ["image/png", "image/jpg", "image/jpeg"];
        
        if(event.target.files[0] && event.target.files[0].size > 3000000){
            this.$warningAlert('Image size exceed 3 mb');
        }else if(event.target.files[0] && !valid.includes(event.target.files[0].type)){
            this.$warningAlert('Image type can be jpg or png');
        }else{
            this.newImage = true
            this.stories.img = event.target.files[0]
        }
        this.$refs.uploadPhoto.value = ""
    },
    uploadVideo(event) {
      const validVideoTypes = ["video/mp4", "video/webm", "video/ogg"];
      const file = event.target.files[0];

      if (file && file.size > 10000000) {
        this.$warningAlert('Video size exceeds 30 mb');
      } else if (file && !validVideoTypes.includes(file.type)) {
        this.$warningAlert('Video type must be mp4, webm, or ogg');
      } else {
        const videoURL = URL.createObjectURL(file); // Создаем URL для видео
        const videoElement = document.createElement("video"); // Создаем HTMLVideoElement
        videoElement.src = videoURL;

        // Слушаем событие загрузки метаданных
        videoElement.onloadedmetadata = () => {
          if (videoElement.duration > 60) { // Проверка на длительность видео
            this.$warningAlert('Video duration exceeds 60 seconds');
            URL.revokeObjectURL(videoURL); // Освобождаем память от URL
          } else {
            this.stories.video = file; // Если видео подходит по длительности, сохраняем его
          }
        };
      }
      this.$refs.uploadVideo.value = ""; // Очищаем поле
    },
    clearInput(type){
        if (type === 'img') {
          this.$refs.uploadPhoto.value = "";
          this.stories.img = "";
          this.newImage = false;
        } else if (type === 'video') {
          this.$refs.uploadVideo.value = "";
          this.stories.video = "";
          this.newVideo = false;
        }
    },
    onSubmit() {
      let stories = this.stories;
      const form = new FormData();
      
      if (stories.img) {
        form.append('img', stories.img);
      }

      if (stories.video) {
        form.append('video', stories.video);
      }

      form.append('name', stories.name);

      this.axios.post(this.url('addStories'), form)
        .then(() => {
          setTimeout(() => {
            this.$emit('getStories');
            $('#add-stories').modal("hide");
            this.stories = {
              name: '',
              img: '',
              video: ''
            };
          }, 500);
          this.$successAlert('Stories has been added');
        }).catch((error) => {
          if (error.response && error.response.data) {
            this.$warningAlert(error.response.data.msg);
          }
        });
    },
    makeImg(name){
        return this.img(name)
    },
    makeVideo(){
        return "https://app.loygift.com/" + this.stories.video
    }

  },
  mounted(){
  }

}
</script>

<style scoped>

/* Стили для видео предпросмотра */
.video-preview .video-new {
  max-width: 270px;
  object-fit: cover;
}

.deleteVideo img {
  opacity: 0.75;
  transition: 0.3s ease-in;
}
.deleteVideo:hover img {
  opacity: 1;
  transition: 0.3s ease-out;
}
.deleteVideo {
  max-width: 270px;
  height: 20px;
  
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: none;
  border: none;
}

.modal.fade:not(.in).right .modal-dialog {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0, 0, 0);
}
.valid-label{
  margin-bottom: 22px !important;
}

.show-price{
  display:none;
}
.show-price.active{
  display: block;
}
.product-calendar{
  margin-bottom: 40px;
}
.image-preview .img-new{
  height: 192px;
  object-fit: cover;
}
.product-calendar label{
  color: #8C94A5;
}
.calendar{
  margin-bottom: 0;
  padding: 0 10px;
}
.modal-form input{
  width: 100%;
  font-size: 16px;
}
.modal-form label{
  margin-right: 10px;
  margin-bottom: 8px;
}
.modal-img{
  margin-bottom: 60px;
}
.modal-img p{
  margin-bottom: 15px;
  color: #828282;
  font-size: 12px;
}
.add-russian{
  display: block;
  margin: 20px 0;
  color:#616cf5;
}
.parent{
  margin-bottom: 130px;
}
.save,.cancel{
  width: 120px;
}
.add-stories{
  padding: 0 30px;
}
.stories-title span{
  color:#b0b0b0;
}
.stories-title{
  font-size: 14px;
  font-weight: normal;
}
.stories-description{
  color:#858585;
  font-size: 14px;
}
.margin-bottom-10{
  margin-bottom: 10px;
}

.margin-bottom-20{
  margin-bottom: 20px;
}
.margin-bottom-30{
  margin-bottom: 30px;
}
.upload-photo{
  background: none;
  border: 1px solid #616CF5;
  border-radius: 5px;
  padding: 10px 25px;
  color:#616cf5;
}
.selects .form-control{
  background: #F8F9FB;
  border:1px solid #d3d3d3;
}
.selects:before{
  content:'';
  background: url("../../assets/icons/selectDown.svg") no-repeat;
  width:20px;
  height:20px;
  position: absolute;
  z-index:88;
  right: 20px;
  top:27%;
}
.selects{
  position: relative;
}
.calendar-period{
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  height:45px;
  padding:0 10px;
  width:100%
}
.deleteImage img{
    opacity: 0;
    transition: 0.3s ease-in;
}
.deleteImage:hover img{
    opacity: 1;
    transition: 0.3s ease-out;
}
.deleteImage{
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background: none;
    border: none;
}
</style>