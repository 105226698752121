<template>
<div>
    <div v-for="stories in  storiesList" :key="stories._id" class="table-item d-flex align-items-center">
        
        <div style="width:45%">
            <div  class="d-flex align-items-center">
                <div v-if="stories.img" class="table-img">
                    <img :src="makeImg(stories.img)">
                </div>
                <div v-else class="table-img">
                    <img src="../../assets/img/video.png">
                </div>
                <p class="w-auto  of-ellipsis mb-0">
                    {{stories.name}}
                </p>
            </div>
        </div>
        <div style="width:45%">
            {{ stories.updatedAt.slice(0, 10) }}
        </div>
        <div style="width:10%" class="d-flex justify-content-end" >
            <div v-if="check()" class="dropleft dropMenu">
            <div class="dropdown-toggle d-flex justify-content-end" id="dropdownMenuTotal" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img  src="../../assets/icons/three-dots.svg"
                    class="three-dots">
            </div>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuTotal">
                <ul class="list-group">
                <li class="list-group-item"  data-toggle="modal" data-target="#edit-stories" @click="$emit('selectStories', stories)">Edit</li>
                <li class="list-group-item" v-on:click="$emit('deleteStories' ,stories._id)">Delete</li>
                </ul>
            </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "StoriesItem",
    props:['storiesList'],
    methods:{
        check(access="stories", parametr="active", parametr2="canEdit"){
            return this.checkAccess(access, parametr, parametr2)
        },
        sendStoriesNotification(storiesID){
            this.axios.get(this.url('sendStoriesPN', storiesID))
                .then(() => {
                    this.$successAlert('Notification is send')
                }).catch((error)=>{
                    if(error.response && error.response.data){
                        this.$warningAlert(error.response.data.msg)
                    }
                });
        },
        makeImg(name){
            return this.img(name)
        }
    }
}
</script>

<style scoped>
.stories-btn{
  border:none;
  background: #E9ECFF;
  border-radius: 5px;
  width: 34px;
  height: 34px;
}
.table-item{
 padding: 10px 40px;
}
</style>