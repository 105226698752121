<template>
  <div class="container">
    <nav class="navigation d-flex align-items-center justify-content-between">
      <!-- Логотип слева -->
      <router-link :to="`/${currentCompanyCatalog}`" class="brand-navbar">
        <img class="web-catalog-logo" v-if="catalog_settings.logo && catalog_settings.logo !==''" :src="server+'/'+catalog_settings.logo">
        <span v-else>
          {{catalog_settings.name || 'Company Name'}}
        </span>
      </router-link>

      

      <!-- Центральное меню -->
      <ul class="client-menu">
        <li @click="removeActiveFromLink" class="client-list">
          <router-link class="client-link" :to="`/${currentCompanyCatalog}/about`">About</router-link>
        </li>
        <li @click="removeActiveFromLink" class="client-list">
          <router-link class="client-link" :to="`/${currentCompanyCatalog}/catalog-page`">Catalog</router-link>
        </li>
        <li @click="removeActiveFromLink" class="client-list">
          <router-link class="client-link" :to="`/${currentCompanyCatalog}/about`">Contacts</router-link>
        </li>
      </ul>

      <!-- Иконки справа -->
      <div class="icons-wrapper d-flex align-items-center">
        <router-link :to="`/${currentCompanyCatalog}/catalog-page`" class="nav-icon">
          <img src="../assets/clients/search.svg" alt="Search" />
        </router-link>
        <span v-if="!catalog_settings.catalogMode && !catalog_settings.foodMode" class="nav-icon">
          <router-link v-if="!isLogged" :to="`/${currentCompanyCatalog}/signin`">
            <img src="../assets/clients/login.svg" alt="Login" />
          </router-link>
          <router-link v-else :to="`/${currentCompanyCatalog}/client-account`">
            <img src="../assets/clients/login.svg" alt="My Account" />
          </router-link>
        </span>

        <router-link v-if="!catalog_settings.catalogMode" :to="`/${currentCompanyCatalog}/basket`" class="basket-icon nav-icon">
          <img src="../assets/clients/busket.svg" alt="Basket" />
          <div v-if="countOrders > 0" class="bg-not d-flex align-items-center">
            <span>{{countOrders}}</span>
          </div>
        </router-link>
      </div>


      <!-- Бургер-меню для мобильных устройств -->
      <div class="burger-menu" @click="toggleMobileMenu">
        <img src="../assets/img/more.png" alt="Menu" />
      </div>

      <!-- Мобильное меню -->
      <div class="mobile-menu" :class="{ 'active': isMobileMenuOpen }">
        <div class="mobile-menu-header">
          <span class="close-menu" @click="toggleMobileMenu">&times;</span>
        </div>
        <ul class="mobile-menu-list">
          <li @click="removeActiveFromLink">
            <router-link :to="`/${currentCompanyCatalog}/about`">About</router-link>
          </li>
          <li @click="removeActiveFromLink">
            <router-link :to="`/${currentCompanyCatalog}/catalog-page`">Catalog</router-link>
          </li>
          <li @click="removeActiveFromLink">
            <router-link :to="`/${currentCompanyCatalog}/about`">Contacts</router-link>
          </li>
          <li>
            <router-link :to="`/${currentCompanyCatalog}/catalog-page`">
              <img src="../assets/clients/search.svg" alt="Search" /> Search
            </router-link>
          </li>
          <li v-if="!catalog_settings.catalogMode && !catalog_settings.foodMode">
            <router-link v-if="!isLogged" :to="`/${currentCompanyCatalog}/signin`">
              <img src="../assets/clients/login.svg" alt="Login" /> Login
            </router-link>
            <router-link v-else :to="`/${currentCompanyCatalog}/client-account`">
              <img src="../assets/clients/login.svg" alt="My Account" /> My Account
            </router-link>
          </li>
          <li v-if="!catalog_settings.catalogMode">
            <router-link :to="`/${currentCompanyCatalog}/basket`">
              <img src="../assets/clients/busket.svg" alt="Basket" /> Basket
              <span v-if="countOrders > 0" class="badge">{{countOrders}}</span>
            </router-link>
          </li>
        </ul>
      </div>

      <!-- ... остальной код ... -->
    </nav>
  </div>
  <!--Centered Modal-->
  <div class="parent-modal">
    <div class="modal myModal fade" id="orderStatus"  role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content category-content">
          <div class="modal-header category-header align-items-center">
            <h3 class="modal-title orderStatusText">
            </h3>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close mr-0">
                  <span aria-hidden="true">
                    <img src="../assets/icons/xBlack.svg" alt="">
                  </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
export default {
  name: "Navbar",
  data(){
    return{
      show_mini_basket:false,
      isMobileMenuOpen: false,
    }
  },
  computed:{
    //Contacts company
    companyAddresses(){
      return this.$store.getters['Catalog/getCompanyAddresses'];
    },

    user(){
      return this.$store.getters['Client/getUser'];
    },
  // ...mapGetters(["Orders/countOrders" ,"Orders/shoppingCart"]),
    company_url_basket(){
      return this.$store.getters['Orders/getCompany_url_basket'];
    },
    countOrders(){
      if(this.currentCompanyCatalog!==this.company_url_basket){
        return 0;
      }
      return this.$store.getters['Orders/countOrders'];

    },
    shoppingCart(){

      if(this.currentCompanyCatalog!==this.company_url_basket){
        return [];
      }
      return this.$store.state.Orders.shoppingCart;
    },
    currentCompanyCatalog() {
      return this.$route.params.bekon;
    },
    catalog_settings(){
      return this.$store.getters['Catalog/getCatalog_settings'];
    },
    isLogged(){
      return this.$store.getters['Client/getUserStatus'];
    },
    server(){
      return this.$server;
    },

  },
  methods:{
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      document.body.style.overflow = this.isMobileMenuOpen ? 'hidden' : '';
    },
    removeActiveFromLink(){
      console.log("removeActiveFromLink","imp");
      if (this.isMobileMenuOpen) {
        this.isMobileMenuOpen = false;
        document.body.style.overflow = '';
      }
    },
    removeActive(){
      console.log("removeActive","imp");
      $('.menu-wrapper').removeClass('active')
      setTimeout(()=>{
        $('.backdrop-menu').removeClass('active')
      },400);
      const scrollY = document.body.style.top;
      document.body.style.top = '';
      document.body.style.position = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    },
    showNavbar(){
      console.log("showNavbar","imp");
      $('.menu-wrapper').addClass('active')
      $('.backdrop-menu').addClass('active')
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    },
    logout(){
      this.$store.dispatch("Client/logout");
      this.$store.dispatch("Orders/clearAll");
      this.$router.push({ path: `/${this.currentCompanyCatalog}`});
    },
    close_drop(){
        $('.basket-hover').css('visibility', 'hidden');
    },
    mouser(){
      $('.basket-hover').css('visibility', 'visible');
    },
    mouser_leave(){
      $('.basket-hover').css('visibility', 'hidden');
    }
  },
  created() {
    if(this.user) {
      this.socket.on("sendingHey", function (data) {
        $('#orderStatus').modal('show');
        let text = `Order #${data.code} is ${data.status}`;
        $('.orderStatusText').text(text);
      });
    }
  },

}
</script>

<style scoped>







.nav-icon {
  margin-right: 10px; /* Вы можете настроить это значение по вашему усмотрению */
}

.nav-icon:last-child {
  margin-right: 0; /* Убираем отступ у последнего элемента */
}

.backdrop-menu.active{
  display: block;
}
.backdrop-menu{
  display: none;
  width: 100%;
  height:100%;
  position: fixed;
  top:0;
  left: 0;
  background: #000;
  opacity:0.5;
  z-index:99;
}
.contact{
  position: absolute;
  bottom: 20px;
  left:20px;
  border-top: 1px solid #e7e7e7;
  width: 88%;
  padding-top: 20px;
  display:none;
}
.router-link-active{
  background: none;
}
.contact-title{
  color: #222222;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 17px;
}
.footer-info{
  margin-bottom: 10px;
  display: block;
  color:#484848;
}
.list-span{
  margin-right: 40px;
}
.web-catalog-logo{
  height:45px;
}
.footer-info img{
  margin-right: 10px;
}
.footer-info a, .footer-info a:hover{
  color:#484848;
  text-decoration: none;
}

.log-out{
  display: none;
}

.close-nav{
  width: 24px;
  height: 24px;
}

.menu-wrapper.active{
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index:9999;
  top: 0;
  left: 0;
  background: #fafafa;
  padding: 0 20px;
  transition:.4s;
  overflow:hidden;


}
.mobile-basket ,.basket-menu{
  display:none;

}
.mobile-basket{
  width: 26px;
  height: 26px;
}
.basket-menu{
  position: relative;
  padding: 5px;

}
.basket-menu img{
  width: 24px;
  height: 24px;
}
.basket-menu .bg-not{
  position: absolute;
  top:0;
  right:0;


}
.menu-wrapper .mobile-header{
  display:none !important;
}
.menu-wrapper.active .mobile-header{
  display: flex !important;
}
.menu-wrapper.active .client-menu {
  margin: auto;
  display: block;
  padding: 0;
}
.menu-wrapper.active .client-list{
  margin-bottom: 20px;
  margin-right: 0;
}
.client-list img{
  width: 20px;
  height: 20px;
}
.burger{
  display: none;
}

.navigation {
  height: 62px;
  border-bottom: 1px solid #E4E4E4;
  justify-content: space-between;
  position: relative;
}

.brand-navbar{
  color: #222222;
font-size: 18px;
  text-decoration: none;
  font-weight:normal ;
}
.brand-navbar span{
  font-weight: 600;
}

.client-menu{
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}
.client-list{
  list-style-type:none ;
  margin: 0 20px;
  cursor: pointer;
}
.client-list.hoverBasket{
  margin-right: 0;
}
.client-list img{
  margin-right: 10px;
}
.client-link{
  font-weight: normal;
  color: #222222;
  text-decoration: none;
font-size: 14px;
}

.bg-not{
  height: 14px;
  width: 14px;
  background: #616cf5;
  margin-left: 7px;
  color:#fff;
  border-radius: 50%;
  justify-content: center;
  font-size:10px;
  align-items:center;

}

.basket-title{
  width: 10rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow:hidden;
}
.basket-hover{
  background: #FFFFFF;
  width: 20rem;
  box-shadow: 2px 11px 35px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  padding-bottom:24px;
  transition: .3s;
  position: absolute;
  right:0;
  top: 100px;
  margin: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;

}
.hoverBasket:hover .basket-hover{
  opacity: 1;
  top: 30px;
  visibility: visible;
}

.basket-hover .save{
  width:100%;
  margin-top: 20px;
}
.basket-header{
  padding-bottom:10px;
}
.basket-header h5{
  color:#222;
  font-weight: 600;
  font-size: 20px;
}
.basket-img{
  height:60px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.basket-img img{
  width: 100%;
  height: 100%;
}
.basket-title{
  font-size:16px;
  font-weight: normal;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:179px ;
}
.basket-code{
  color: #B0B0B0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.basket-price{
  font-size: 18px;
  font-weight: normal;
}
.scroll-basket{
  max-height: 250px;
  overflow-y: auto;
}
.empty_basket_title{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #B0B0B0;
}
.catalog_logo{
  background-repeat: no-repeat;
  max-height: 55px;
  height: 45px;
  max-width: 45px;
  width: 55px;
  background-size: contain;
  background-position: center;
}
@media(min-width:1200px){
  .client-container{
    width: calc(100vw - 240px);
  }
}




.burger-menu {
  display: none;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.burger-menu img {
  width: 30px;
  height: 30px;
}

.burger-menu span {
  display: block;
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s;
}

.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  left: -100%;
  width: 80%;
  height: 100vh;
  background-color: #fff;
  z-index: 1000;
  transition: left 0.3s ease-in-out;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
}

.mobile-menu.active {
  left: 0;
}

.mobile-menu-header {
  padding: 20px;
  border-bottom: 1px solid #e4e4e4;
}

.close-menu {
  font-size: 24px;
  cursor: pointer;
}

.mobile-menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-menu-list li {
  padding: 15px 20px;
  border-bottom: 1px solid #e4e4e4;
}

.mobile-menu-list a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.mobile-menu-list img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.badge {
  background-color: #616cf5;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: 5px;
}

@media (max-width: 768px) {

  html, body {
    max-width: 100%;
    overflow-x: hidden;
  }

  .burger-menu {
    display: flex;
  }

  .mobile-menu {
    display: block;
  }

  .client-menu, 
  .icons-wrapper {
    display: none !important;
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    left: -9999px;
  }

  .navigation {
    justify-content: space-between;
    padding: 10px 0;
  }

  .brand-navbar {
    font-size: 16px;
  }

  .web-catalog-logo {
    height: 35px;
  }
}

</style>