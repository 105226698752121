<template>

  <li :class="[isFolder ? 'folder' : 'file']" >
    <!-- <div class="__mainContainer" style="display: flex; padding: 12px;" :class='classes' @click="toggle" @dblclick="changeType">
      
      <div style="display: flex; padding-left: 15px; padding-top: 10px;">
        <img style="height: 20px; width: 40px" src="../../assets/img/1c_logo.png">
      </div>

      <div style="display: flex; padding-left: 30px; padding-top: 8px;">
        <label class="switch d-flex" style="padding-right: 36px; margin-top: 4px;">
          <input v-model="settings.test_1" type="checkbox" @click="styleChange">
          <span class="slider round"></span>
        </label>
        <label>
          <span style="font-size: 18px; font-weight:600; white-space: nowrap;">1c exchange</span>
          <span v-if="isFolder"></span>
        </label>
      </div>


      <div style="flex: 1;"></div>
      <div style="margin-left: auto; margin-top: 10px;" v-if="open">
        <span style="color:#B0B0B0;font-size: 14px; margin-right: 20px;">hide</span>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.24106 8.07244C4.53326 7.77438 4.99051 7.74728 5.31272 7.99115L5.40503 8.07244L12 14.8L18.595 8.07244C18.8872 7.77438 19.3444 7.74728 19.6666 7.99115L19.7589 8.07244C20.0511 8.3705 20.0777 8.83691 19.8386 9.16558L19.7589 9.25974L12.582 16.5806C12.2898 16.8787 11.8325 16.9058 11.5103 16.6619L11.418 16.5806L4.24106 9.25974C3.91965 8.93188 3.91965 8.4003 4.24106 8.07244Z" fill="#200E32"/>
        </svg>
      </div>
      <div style="margin-left: auto; margin-top: 10px;" v-else>
        <span style="color:#B0B0B0;font-size: 14px; margin-right: 20px;">show</span>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.7589 17.1232C19.4667 17.4213 19.0095 17.4484 18.6873 17.2045L18.595 17.1232L12 10.3957L5.40503 17.1232C5.11283 17.4213 4.65558 17.4484 4.33337 17.2045L4.24106 17.1232C3.94886 16.8252 3.9223 16.3588 4.16137 16.0301L4.24106 15.9359L11.418 8.61504C11.7102 8.31698 12.1675 8.28988 12.4897 8.53375L12.582 8.61504L19.7589 15.9359C20.0804 16.2638 20.0804 16.7954 19.7589 17.1232Z" fill="#200E32"/>
        </svg>
      </div>
      
      
    </div> -->
    
    <ul v-show="open" v-if="isFolder" :class="{'open': open}" style="padding-top: 30px; padding-bottom: 20px;">
      <div class="__mainContainer" style="display: flex">
        <div style="flex: 1;margin-right: 3px;margin-bottom: 10px; max-width: 25%;">
          <span style="color:#B0B0B0;font-size: 13px; margin-bottom: 15px;">Здесь вы можете самостоятельно интегрироваться с 1с.</span>
        <p style="color:#838383;margin-top: 15px;">Настройки импорта 1с 8*, в 1с должен быть модуль обмена сайтами (Обмен с сайтом), не редактируйте эти данные, они используются для проверки подключения к вашему серверу 1с</p>
        </div>
        
        <div style="flex: 1;margin-left: 7%;margin-right: 5%;margin-bottom: 10px;">

          <input v-model="oneCApiAddress" class="form-input cashback-input " placeholder="Api">
          <input v-model="oneCApiLogin" class="form-input cashback-input " placeholder="User name">
          <input v-model="oneCApiPassword" class="form-input cashback-input " placeholder="Password">

        </div>

        <div style="flex: 1;margin-right: 40px;margin-bottom: 10px;">
          <div class="d-flex enable-title">
            <div class="tooltip-box">
              <svg class="tooltip-icon" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 15px;margin-top: -5px;">
                <circle cx="9.6853" cy="9.39148" r="8.92554" fill="#EAEAEA"/>
                <path d="M6.34814 7.73157C6.38232 6.86731 6.6875 6.16418 7.26367 5.62219C7.84473 5.0802 8.64795 4.8092 9.67334 4.8092C10.6304 4.8092 11.4092 5.06555 12.0098 5.57825C12.6104 6.08606 12.9106 6.73547 12.9106 7.52649C12.9106 8.58606 12.3979 9.42346 11.3726 10.0387C10.9038 10.317 10.584 10.5758 10.4131 10.8151C10.2422 11.0494 10.1567 11.3571 10.1567 11.7379V12.1847H8.64062L8.6333 11.5988C8.61377 11.0909 8.71631 10.6588 8.94092 10.3024C9.17041 9.94592 9.53906 9.61389 10.0469 9.30627C10.4961 9.03772 10.8086 8.77893 10.9844 8.52991C11.1602 8.28088 11.248 7.96838 11.248 7.59241C11.248 7.17249 11.0967 6.82825 10.7939 6.55969C10.4912 6.29114 10.0908 6.15686 9.59277 6.15686C9.08984 6.15686 8.68457 6.29846 8.37695 6.58167C8.06934 6.86487 7.89844 7.24817 7.86426 7.73157H6.34814ZM9.40967 15.5758C9.14111 15.5758 8.91406 15.4855 8.72852 15.3048C8.54297 15.1241 8.4502 14.8995 8.4502 14.631C8.4502 14.3624 8.54297 14.1378 8.72852 13.9572C8.91406 13.7765 9.14111 13.6862 9.40967 13.6862C9.68311 13.6862 9.9126 13.7765 10.0981 13.9572C10.2837 14.1378 10.3765 14.3624 10.3765 14.631C10.3765 14.8995 10.2837 15.1241 10.0981 15.3048C9.9126 15.4855 9.68311 15.5758 9.40967 15.5758Z" fill="black"/>
              </svg>
              <div
                class="tooltip"
              >
                <span
                  class="text"
                >(Группировать одинаковые товары как один, сейчас группирует по значению текста в скобках. Пример: Футболка СМ 48007-N8 (48) и Футболка СМ 48007-N8 (50), будут как один товар с размерами 48 и 50)</span>
              </div>
            </div>
            <label class="switch d-flex" style="padding-right: 40px">
              <input v-model="settings.onec_check1" type="checkbox" @click="updateCheck_onec1">
              <span class="slider round"></span>
            </label>
            <span style="margin-top: -5px; color:#838383;">Group items as one if they have same names but different properties/sizes.</span>
          </div>
          <br>
          <div class="d-flex enable-title">
            <div class="tooltip-box">
                <svg class="tooltip-icon" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 15px;margin-top: -5px;">
                  <circle cx="9.6853" cy="9.39148" r="8.92554" fill="#EAEAEA"/>
                  <path d="M6.34814 7.73157C6.38232 6.86731 6.6875 6.16418 7.26367 5.62219C7.84473 5.0802 8.64795 4.8092 9.67334 4.8092C10.6304 4.8092 11.4092 5.06555 12.0098 5.57825C12.6104 6.08606 12.9106 6.73547 12.9106 7.52649C12.9106 8.58606 12.3979 9.42346 11.3726 10.0387C10.9038 10.317 10.584 10.5758 10.4131 10.8151C10.2422 11.0494 10.1567 11.3571 10.1567 11.7379V12.1847H8.64062L8.6333 11.5988C8.61377 11.0909 8.71631 10.6588 8.94092 10.3024C9.17041 9.94592 9.53906 9.61389 10.0469 9.30627C10.4961 9.03772 10.8086 8.77893 10.9844 8.52991C11.1602 8.28088 11.248 7.96838 11.248 7.59241C11.248 7.17249 11.0967 6.82825 10.7939 6.55969C10.4912 6.29114 10.0908 6.15686 9.59277 6.15686C9.08984 6.15686 8.68457 6.29846 8.37695 6.58167C8.06934 6.86487 7.89844 7.24817 7.86426 7.73157H6.34814ZM9.40967 15.5758C9.14111 15.5758 8.91406 15.4855 8.72852 15.3048C8.54297 15.1241 8.4502 14.8995 8.4502 14.631C8.4502 14.3624 8.54297 14.1378 8.72852 13.9572C8.91406 13.7765 9.14111 13.6862 9.40967 13.6862C9.68311 13.6862 9.9126 13.7765 10.0981 13.9572C10.2837 14.1378 10.3765 14.3624 10.3765 14.631C10.3765 14.8995 10.2837 15.1241 10.0981 15.3048C9.9126 15.4855 9.68311 15.5758 9.40967 15.5758Z" fill="black"/>
                </svg>
            
              <div class="tooltip">
                <span
                  class="text"
                >(Удалить товары с сайта, если их нет в новом импорте)</span>
              </div>
              </div>
            <label class="switch d-flex" style="padding-right: 40px" >
              <input v-model="settings.onec_check2" type="checkbox" @click="updateCheck_onec2">
              <span class="slider round"></span>
            </label>
            <span style="margin-top: -5px; color:#838383;">Delete products that are not found in the new import.</span>
          </div>
          <br>
          <div class="d-flex enable-title">
            <div class="tooltip-box">
              <svg class="tooltip-icon" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 15px;margin-top: -5px;">
                <circle cx="9.6853" cy="9.39148" r="8.92554" fill="#EAEAEA"/>
                <path d="M6.34814 7.73157C6.38232 6.86731 6.6875 6.16418 7.26367 5.62219C7.84473 5.0802 8.64795 4.8092 9.67334 4.8092C10.6304 4.8092 11.4092 5.06555 12.0098 5.57825C12.6104 6.08606 12.9106 6.73547 12.9106 7.52649C12.9106 8.58606 12.3979 9.42346 11.3726 10.0387C10.9038 10.317 10.584 10.5758 10.4131 10.8151C10.2422 11.0494 10.1567 11.3571 10.1567 11.7379V12.1847H8.64062L8.6333 11.5988C8.61377 11.0909 8.71631 10.6588 8.94092 10.3024C9.17041 9.94592 9.53906 9.61389 10.0469 9.30627C10.4961 9.03772 10.8086 8.77893 10.9844 8.52991C11.1602 8.28088 11.248 7.96838 11.248 7.59241C11.248 7.17249 11.0967 6.82825 10.7939 6.55969C10.4912 6.29114 10.0908 6.15686 9.59277 6.15686C9.08984 6.15686 8.68457 6.29846 8.37695 6.58167C8.06934 6.86487 7.89844 7.24817 7.86426 7.73157H6.34814ZM9.40967 15.5758C9.14111 15.5758 8.91406 15.4855 8.72852 15.3048C8.54297 15.1241 8.4502 14.8995 8.4502 14.631C8.4502 14.3624 8.54297 14.1378 8.72852 13.9572C8.91406 13.7765 9.14111 13.6862 9.40967 13.6862C9.68311 13.6862 9.9126 13.7765 10.0981 13.9572C10.2837 14.1378 10.3765 14.3624 10.3765 14.631C10.3765 14.8995 10.2837 15.1241 10.0981 15.3048C9.9126 15.4855 9.68311 15.5758 9.40967 15.5758Z" fill="black"/>
              </svg>
              <div
                class="tooltip"
              >
                <span
                  class="text"
                >(Не заменять изображения старых товаров, при импорте)</span>
              </div>
            </div>
            <label class="switch d-flex" style="padding-right: 40px">
              <input v-model="settings.onec_check3" type="checkbox" @click="updateCheck_onec3" >
              <span class="slider round"></span>
            </label>
            <span style="margin-top: -5px; color:#838383;">Do not update images of old products on import.</span>
          </div>
        </div>

        
          
      </div>
      
      
    </ul>
  </li>


  <li :class="[isFolder ? 'folder' : 'file']" >
    <div class="__mainContainer" style="display: flex; padding: 12px; margin-top: 10px;" :class='classes_pos' @click="toggle_pos" @dblclick="changeType_pos">
      
      <div style="display: flex; padding-left: 15px; padding-top: 1px;">
        <img style="height: 40px; width: 40px" src="../../assets/img/poster_logo.png">
      </div>

      <div style="display: flex; padding-left: 30px; padding-top: 8px;">
        <label class="switch d-flex" style="padding-right: 36px; margin-top: 4px;">
          <input v-model="settings.test_2" type="checkbox" @click="styleChange_pos">
          <span class="slider round"></span>
        </label>
        <label>
          <span style="font-size: 18px; font-weight:600; white-space: nowrap;">Poster Pos</span>
          <span v-if="isFolder"></span>
        </label>
      </div>


      <div style="flex: 1;"></div>
      <div style="margin-left: auto; margin-top: 10px;" v-if="open_pos">
        <span style="color:#B0B0B0;font-size: 14px; margin-right: 20px;">hide</span>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.24106 8.07244C4.53326 7.77438 4.99051 7.74728 5.31272 7.99115L5.40503 8.07244L12 14.8L18.595 8.07244C18.8872 7.77438 19.3444 7.74728 19.6666 7.99115L19.7589 8.07244C20.0511 8.3705 20.0777 8.83691 19.8386 9.16558L19.7589 9.25974L12.582 16.5806C12.2898 16.8787 11.8325 16.9058 11.5103 16.6619L11.418 16.5806L4.24106 9.25974C3.91965 8.93188 3.91965 8.4003 4.24106 8.07244Z" fill="#200E32"/>
        </svg>
      </div>
      <div style="margin-left: auto; margin-top: 10px;" v-else>
        <span style="color:#B0B0B0;font-size: 14px; margin-right: 20px;">show</span>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.7589 17.1232C19.4667 17.4213 19.0095 17.4484 18.6873 17.2045L18.595 17.1232L12 10.3957L5.40503 17.1232C5.11283 17.4213 4.65558 17.4484 4.33337 17.2045L4.24106 17.1232C3.94886 16.8252 3.9223 16.3588 4.16137 16.0301L4.24106 15.9359L11.418 8.61504C11.7102 8.31698 12.1675 8.28988 12.4897 8.53375L12.582 8.61504L19.7589 15.9359C20.0804 16.2638 20.0804 16.7954 19.7589 17.1232Z" fill="#200E32"/>
        </svg>
      </div>
      
      
    </div>
    
    <ul v-show="open_pos" v-if="isFolder" :class="{'open_pos': open_pos}" style="padding-top: 30px; padding-bottom: 20px;">
      <div class="__mainContainer" style="display: flex">
        <div style="flex: 1;margin-right: 3px;margin-bottom: 10px; max-width: 25%;">
          <span style="color:#B0B0B0;font-size: 13px; margin-bottom: 15px;">Здесь вы можете самостоятельно интегрироваться с Poster.</span>
        <p style="color:#838383;margin-top: 15px;">Для интеграции с Poster Pos зайдите в Доступы -> Интеграции и скопируйте токен личной интеграции</p>
        </div>
        
        <div style="flex: 1;margin-left: 7%;margin-right: 5%;margin-bottom: 10px;">

          <input v-model="tokenPosterPos" class="form-input cashback-input " placeholder="Poster ID (Key)">

        </div>

        <div style="flex: 1;margin-right: 40px;margin-bottom: 10px;">
          <div class="d-flex enable-title">
            <div class="tooltip-box">
              <svg class="tooltip-icon" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 15px;margin-top: -5px;">
                <circle cx="9.6853" cy="9.39148" r="8.92554" fill="#EAEAEA"/>
                <path d="M6.34814 7.73157C6.38232 6.86731 6.6875 6.16418 7.26367 5.62219C7.84473 5.0802 8.64795 4.8092 9.67334 4.8092C10.6304 4.8092 11.4092 5.06555 12.0098 5.57825C12.6104 6.08606 12.9106 6.73547 12.9106 7.52649C12.9106 8.58606 12.3979 9.42346 11.3726 10.0387C10.9038 10.317 10.584 10.5758 10.4131 10.8151C10.2422 11.0494 10.1567 11.3571 10.1567 11.7379V12.1847H8.64062L8.6333 11.5988C8.61377 11.0909 8.71631 10.6588 8.94092 10.3024C9.17041 9.94592 9.53906 9.61389 10.0469 9.30627C10.4961 9.03772 10.8086 8.77893 10.9844 8.52991C11.1602 8.28088 11.248 7.96838 11.248 7.59241C11.248 7.17249 11.0967 6.82825 10.7939 6.55969C10.4912 6.29114 10.0908 6.15686 9.59277 6.15686C9.08984 6.15686 8.68457 6.29846 8.37695 6.58167C8.06934 6.86487 7.89844 7.24817 7.86426 7.73157H6.34814ZM9.40967 15.5758C9.14111 15.5758 8.91406 15.4855 8.72852 15.3048C8.54297 15.1241 8.4502 14.8995 8.4502 14.631C8.4502 14.3624 8.54297 14.1378 8.72852 13.9572C8.91406 13.7765 9.14111 13.6862 9.40967 13.6862C9.68311 13.6862 9.9126 13.7765 10.0981 13.9572C10.2837 14.1378 10.3765 14.3624 10.3765 14.631C10.3765 14.8995 10.2837 15.1241 10.0981 15.3048C9.9126 15.4855 9.68311 15.5758 9.40967 15.5758Z" fill="black"/>
              </svg>
              <div
                class="tooltip"
              >
                <span
                  class="text"
                >(Экспоритировать всех сущ. клиентов в постер)</span>
              </div>
            </div>
            <label class="switch d-flex" style="padding-right: 40px">
              <input v-model="settings.poster_check1" type="checkbox" @click="updateCheck_pos1">
              <span class="slider round"></span>
            </label>
            <span style="margin-top: -5px; color:#838383;">Export all exist clients to POSTER. (Экспоритировать всех сущ. клиентов в постер)</span>
          </div>
        </div>

        
          
      </div>
      
      
    </ul>
  </li>


  <li :class="[isFolder ? 'folder' : 'file']" >
    <div class="__mainContainer" style="display: flex; padding: 12px; margin-top: 10px;" :class='classes_fr' @click="toggle_fr" @dblclick="changeType_fr">
      
      <div style="display: flex; padding-left: 15px; padding-top: 5px;">
        <img style="height: 40px; width: 36px" src="../../assets/img/freedompay.png">
      </div>

      <div style="display: flex; padding-left: 34px; padding-top: 8px;">
        <label class="switch d-flex" style="padding-right: 36px; margin-top: 4px;">
          <input v-model="settings.test_3" type="checkbox" @click="styleChange_fr">
          <span class="slider round"></span>
        </label>
        <label>
          <span style="font-size: 18px; font-weight:600;">freedompay</span>
          <span v-if="isFolder"></span>
        </label>
      </div>


      <div style="flex: 1;"></div>
      <div style="margin-left: auto; margin-top: 10px;" v-if="open_fr">
        <span style="color:#B0B0B0;font-size: 14px; margin-right: 20px;">hide</span>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.24106 8.07244C4.53326 7.77438 4.99051 7.74728 5.31272 7.99115L5.40503 8.07244L12 14.8L18.595 8.07244C18.8872 7.77438 19.3444 7.74728 19.6666 7.99115L19.7589 8.07244C20.0511 8.3705 20.0777 8.83691 19.8386 9.16558L19.7589 9.25974L12.582 16.5806C12.2898 16.8787 11.8325 16.9058 11.5103 16.6619L11.418 16.5806L4.24106 9.25974C3.91965 8.93188 3.91965 8.4003 4.24106 8.07244Z" fill="#200E32"/>
        </svg>
      </div>
      <div style="margin-left: auto; margin-top: 10px;" v-else>
        <span style="color:#B0B0B0;font-size: 14px; margin-right: 20px;">show</span>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.7589 17.1232C19.4667 17.4213 19.0095 17.4484 18.6873 17.2045L18.595 17.1232L12 10.3957L5.40503 17.1232C5.11283 17.4213 4.65558 17.4484 4.33337 17.2045L4.24106 17.1232C3.94886 16.8252 3.9223 16.3588 4.16137 16.0301L4.24106 15.9359L11.418 8.61504C11.7102 8.31698 12.1675 8.28988 12.4897 8.53375L12.582 8.61504L19.7589 15.9359C20.0804 16.2638 20.0804 16.7954 19.7589 17.1232Z" fill="#200E32"/>
        </svg>
      </div>
      
      
    </div>
    
    <ul v-show="open_fr" v-if="isFolder" :class="{'open_fr': open_fr}" style="padding-top: 30px; padding-bottom: 20px;">
      <div class="__mainContainer" style="display: flex">
        <div style="flex: 1;margin-right: 3px;margin-bottom: 10px; max-width: 25%;">
          <span style="color:#B0B0B0;font-size: 13px; margin-bottom: 15px;">Здесь вы можете самостоятельно подключить онлайн оплату. Сперва вам нужно будет открыть свой аккаунт в FreedomPay</span>
        <p style="color:#838383;margin-top: 15px;">Для интеграции с FreedomPay зайдите во вкладку разработчикам и скопируйте Секретный ключ для приема платежей и Идентификатор магазина и введите в подходящее поле</p>
        </div>
        
        <div style="flex: 1;margin-left: 7%;margin-right: 5%;margin-bottom: 10px;">

          <input v-model="freedompaySecretKey" class="form-input cashback-input " placeholder="Secret Key">
          <input v-model="freedompayShopId" class="form-input cashback-input " placeholder="Shop Id">

        </div>

        <div style="flex: 1;margin-right: 40px;margin-bottom: 10px;">
          <div class="d-flex enable-title">
            <div class="tooltip-box">
              <svg class="tooltip-icon" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 15px;margin-top: -5px;">
                <circle cx="9.6853" cy="9.39148" r="8.92554" fill="#EAEAEA"/>
                <path d="M6.34814 7.73157C6.38232 6.86731 6.6875 6.16418 7.26367 5.62219C7.84473 5.0802 8.64795 4.8092 9.67334 4.8092C10.6304 4.8092 11.4092 5.06555 12.0098 5.57825C12.6104 6.08606 12.9106 6.73547 12.9106 7.52649C12.9106 8.58606 12.3979 9.42346 11.3726 10.0387C10.9038 10.317 10.584 10.5758 10.4131 10.8151C10.2422 11.0494 10.1567 11.3571 10.1567 11.7379V12.1847H8.64062L8.6333 11.5988C8.61377 11.0909 8.71631 10.6588 8.94092 10.3024C9.17041 9.94592 9.53906 9.61389 10.0469 9.30627C10.4961 9.03772 10.8086 8.77893 10.9844 8.52991C11.1602 8.28088 11.248 7.96838 11.248 7.59241C11.248 7.17249 11.0967 6.82825 10.7939 6.55969C10.4912 6.29114 10.0908 6.15686 9.59277 6.15686C9.08984 6.15686 8.68457 6.29846 8.37695 6.58167C8.06934 6.86487 7.89844 7.24817 7.86426 7.73157H6.34814ZM9.40967 15.5758C9.14111 15.5758 8.91406 15.4855 8.72852 15.3048C8.54297 15.1241 8.4502 14.8995 8.4502 14.631C8.4502 14.3624 8.54297 14.1378 8.72852 13.9572C8.91406 13.7765 9.14111 13.6862 9.40967 13.6862C9.68311 13.6862 9.9126 13.7765 10.0981 13.9572C10.2837 14.1378 10.3765 14.3624 10.3765 14.631C10.3765 14.8995 10.2837 15.1241 10.0981 15.3048C9.9126 15.4855 9.68311 15.5758 9.40967 15.5758Z" fill="black"/>
              </svg>
              <div
                class="tooltip"
              >
                <span
                  class="text"
                >(Не заменять изображения старых товаров, при импорте)</span>
              </div>
            </div>
            <label class="switch d-flex" style="padding-right: 40px">
              <input v-model="settings.freedom_check1" type="checkbox" @click="updateCheck_fr1">
              <span class="slider round"></span>
            </label>
            <span style="margin-top: -5px; color:#838383;">Do not update images of old products on import.</span>
          </div>
        </div>

        
          
      </div>
      
      
    </ul>
  </li>



  <li :class="[isFolder ? 'folder' : 'file']" >
    <div class="__mainContainer" style="display: flex; padding: 12px; margin-top: 10px;" :class='classes_smsnikita' @click="toggle_smsnikita" @dblclick="changeType_smsnikita">
      
      <div style="display: flex; padding-left: 15px; padding-top: 5px;">
        <img style="height: 40px; width: 40px; border-radius: 10px;" src="../../assets/img/smsnikita.png">
      </div>

      <div style="display: flex; padding-left: 34px; padding-top: 8px;">
        <label class="switch d-flex" style="padding-right: 36px; margin-top: 4px;">
          <input v-model="settings.test_4" type="checkbox" @click="styleChange_smsnikita">
          <span class="slider round"></span>
        </label>
        <label>
          <span style="font-size: 18px; font-weight:600;">Sms nikita</span>
          <span v-if="isFolder"></span>
        </label>
      </div>


      <div style="flex: 1;"></div>
      <div style="margin-left: auto; margin-top: 10px;" v-if="open_smsnikita">
        <span style="color:#B0B0B0;font-size: 14px; margin-right: 20px;">hide</span>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.24106 8.07244C4.53326 7.77438 4.99051 7.74728 5.31272 7.99115L5.40503 8.07244L12 14.8L18.595 8.07244C18.8872 7.77438 19.3444 7.74728 19.6666 7.99115L19.7589 8.07244C20.0511 8.3705 20.0777 8.83691 19.8386 9.16558L19.7589 9.25974L12.582 16.5806C12.2898 16.8787 11.8325 16.9058 11.5103 16.6619L11.418 16.5806L4.24106 9.25974C3.91965 8.93188 3.91965 8.4003 4.24106 8.07244Z" fill="#200E32"/>
        </svg>
      </div>
      <div style="margin-left: auto; margin-top: 10px;" v-else>
        <span style="color:#B0B0B0;font-size: 14px; margin-right: 20px;">show</span>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.7589 17.1232C19.4667 17.4213 19.0095 17.4484 18.6873 17.2045L18.595 17.1232L12 10.3957L5.40503 17.1232C5.11283 17.4213 4.65558 17.4484 4.33337 17.2045L4.24106 17.1232C3.94886 16.8252 3.9223 16.3588 4.16137 16.0301L4.24106 15.9359L11.418 8.61504C11.7102 8.31698 12.1675 8.28988 12.4897 8.53375L12.582 8.61504L19.7589 15.9359C20.0804 16.2638 20.0804 16.7954 19.7589 17.1232Z" fill="#200E32"/>
        </svg>
      </div>
      
      
    </div>
    
    <ul v-show="open_smsnikita" v-if="isFolder" :class="{'open_smsnikita': open_smsnikita}" style="padding-top: 30px; padding-bottom: 20px;">
      <div class="__mainContainer" style="display: flex">
        <div style="flex: 1;margin-right: 3px;margin-bottom: 10px; max-width: 25%;">
          <span style="color:#B0B0B0;font-size: 13px; margin-bottom: 15px;">Здесь вы можете самостоятельно подключить смс сервис, он будет работать для регистрации новых клиентов.</span>
        <p style="color:#838383;margin-top: 15px;">Для интеграции с Sms Nikita kg вставьте логин и пароль вашего аккаунта, эти данные приходили вам на почту.</p>
        </div>
        
        <div style="flex: 1;margin-left: 7%;margin-right: 5%;margin-bottom: 10px;">

          <input v-model="smsnikitaLogin" class="form-input cashback-input " placeholder="Login">
          <input v-model="smsnikitaPass" class="form-input cashback-input " placeholder="Password">

        </div>

        <div style="flex: 1;margin-right: 40px;margin-bottom: 10px; opacity: 0;">
          <div class="d-flex enable-title">
            <div class="tooltip-box">
              <svg class="tooltip-icon" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 15px;margin-top: -5px;">
                <circle cx="9.6853" cy="9.39148" r="8.92554" fill="#EAEAEA"/>
                <path d="M6.34814 7.73157C6.38232 6.86731 6.6875 6.16418 7.26367 5.62219C7.84473 5.0802 8.64795 4.8092 9.67334 4.8092C10.6304 4.8092 11.4092 5.06555 12.0098 5.57825C12.6104 6.08606 12.9106 6.73547 12.9106 7.52649C12.9106 8.58606 12.3979 9.42346 11.3726 10.0387C10.9038 10.317 10.584 10.5758 10.4131 10.8151C10.2422 11.0494 10.1567 11.3571 10.1567 11.7379V12.1847H8.64062L8.6333 11.5988C8.61377 11.0909 8.71631 10.6588 8.94092 10.3024C9.17041 9.94592 9.53906 9.61389 10.0469 9.30627C10.4961 9.03772 10.8086 8.77893 10.9844 8.52991C11.1602 8.28088 11.248 7.96838 11.248 7.59241C11.248 7.17249 11.0967 6.82825 10.7939 6.55969C10.4912 6.29114 10.0908 6.15686 9.59277 6.15686C9.08984 6.15686 8.68457 6.29846 8.37695 6.58167C8.06934 6.86487 7.89844 7.24817 7.86426 7.73157H6.34814ZM9.40967 15.5758C9.14111 15.5758 8.91406 15.4855 8.72852 15.3048C8.54297 15.1241 8.4502 14.8995 8.4502 14.631C8.4502 14.3624 8.54297 14.1378 8.72852 13.9572C8.91406 13.7765 9.14111 13.6862 9.40967 13.6862C9.68311 13.6862 9.9126 13.7765 10.0981 13.9572C10.2837 14.1378 10.3765 14.3624 10.3765 14.631C10.3765 14.8995 10.2837 15.1241 10.0981 15.3048C9.9126 15.4855 9.68311 15.5758 9.40967 15.5758Z" fill="black"/>
              </svg>
              <div
                class="tooltip"
              >
                <span
                  class="text"
                >(Не заменять изображения старых товаров, при импорте)</span>
              </div>
            </div>
            <label class="switch d-flex" style="padding-right: 40px">
              <input v-model="settings.smsnikita_check1" type="checkbox" @click="updateCheck_smsnikita1">
              <span class="slider round"></span>
            </label>
            <span style="margin-top: -5px; color:#838383;">Do not update images of old products on import.</span>
          </div>
        </div>

        
          
      </div>
      
      
    </ul>
  </li>
  
  
</template>

<script>
import Vue from "vue";
import { debounce } from 'lodash';

export default {
  name: "IntegrationItem",
  props: {
    model: Object,
    text: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      open: false,
      open_pos: false,
      open_fr: false,
      open_smsnikita: false,
      settings:{},
      c1_open: false,
      classes:{
            opened: false,
            closed: true,
      },
      classes_pos:{
            opened: false,
            closed: true,
      },
      classes_fr:{
            opened: false,
            closed: true,
      },
      classes_smsnikita:{
            opened: false,
            closed: true,
      },
      oneCApiAddress:"",
      oneCApiLogin:"",
      oneCApiPassword: "",
      tokenPosterPos: "",
      freedompaySecretKey: "",
      freedompayShopId: "",
      smsnikitaLogin: "",
      smsnikitaPass: "",
      id: "",
      isInitialized: false, // Добавлено
    };
  },
  computed: {
    isFolder: function() {
      return this.model.children && this.model.children.length;
    }
  },
  methods: {
    updateCheck_smsnikita1: function() {
      let settings = this.settings;

      if (settings.smsnikita_check1) {
        settings.smsnikita_check1 = false;
      } else {
        settings.smsnikita_check1 = true;
      }

      this.updateSettingsGeneral();
    },
    updateCheck_fr1: function() {
      let settings = this.settings;

      if (settings.freedom_check1) {
        settings.freedom_check1 = false;
      } else {
        settings.freedom_check1 = true;
      }

      this.updateSettingsGeneral();
    },
    updateCheck_onec1: function() {
      let settings = this.settings;

      if (settings.onec_check1) {
        settings.onec_check1 = false;
      } else {
        settings.onec_check1 = true;
      }

      this.updateSettingsGeneral();
    },
    updateCheck_onec2: function() {
      let settings = this.settings;

      if (settings.onec_check2) {
        settings.onec_check2 = false;
      } else {
        settings.onec_check2 = true;
      }

      this.updateSettingsGeneral();
    },
    updateCheck_onec3: function() {
      let settings = this.settings;

      if (settings.onec_check3) {
        settings.onec_check3 = false;
      } else {
        settings.onec_check3 = true;
      }

      this.updateSettingsGeneral();
    },
    updateCheck_pos1: function() {
      let settings = this.settings;

      if (settings.poster_check1) {
        settings.poster_check1 = false;
      } else {
        settings.poster_check1 = true;
      }

      this.updateSettingsGeneral();
    },
    styleChange: function() {
      let c = this.classes;

      if (c.opened) {
        c.opened = false;
        c.closed = true;
        this.updateSettingsGeneral();
      } else {
        c.opened = true;
        c.closed = false;
        this.updateSettingsGeneral();
      }
    },
    styleChange_pos: function() {
      let c = this.classes_pos;

      if (c.opened) {
        c.opened = false;
        c.closed = true;
        this.updateSettingsGeneral();

      } else {
        c.opened = true;
        c.closed = false;
        this.updateSettingsGeneral();
      }
    },
    styleChange_fr: function() {
      let c = this.classes_fr;

      if (c.opened) {
        c.opened = false;
        c.closed = true;
        this.updateSettingsGeneral();
      } else {
        c.opened = true;
        c.closed = false;
        this.updateSettingsGeneral();
      }
    },
    styleChange_smsnikita: function() {
      let c = this.classes_smsnikita;

      if (c.opened) {
        c.opened = false;
        c.closed = true;
        this.updateSettingsGeneral();
      } else {
        c.opened = true;
        c.closed = false;
        this.updateSettingsGeneral();
      }
    },
    toggle: function() {
      if (this.isFolder) {
        this.open = !this.open;
      }
    },
    changeType: function() {
      if (!this.isFolder) {
        Vue.set(this.model, "children", []);
        this.addChild();
        this.open = true;
      }
    },
    toggle_pos: function() {
      if (this.isFolder) {
        this.open_pos = !this.open_pos;
      }
    },
    changeType_pos: function() {
      if (!this.isFolder) {
        Vue.set(this.model, "children", []);
        this.addChild();
        this.open_pos = true;
      }
    },
    toggle_fr: function() {
      if (this.isFolder) {
        this.open_fr = !this.open_fr;
      }
    },
    toggle_smsnikita: function() {
      if (this.isFolder) {
        this.open_smsnikita = !this.open_smsnikita;
      }
    },
    changeType_fr: function() {
      if (!this.isFolder) {
        Vue.set(this.model, "children", []);
        this.addChild();
        this.open_fr = true;
      }
    },
    changeType_smsnikita: function() {
      if (!this.isFolder) {
        Vue.set(this.model, "children", []);
        this.addChild();
        this.open_smsnikita = true;
      }
    },
    updateSettings: debounce(function() {
      let that=this;
      let url = this.url('updatePersonalSettings');
      this.axios.put(url, {
        _id:this.id,
        oneCApiAddress:this.oneCApiAddress,
        oneCApiLogin:this.oneCApiLogin,
        oneCApiPassword:this.oneCApiPassword,
      }).then(function (response) {
        console.log(response);
        that.$successAlert('Updated');
      }).catch(function(error){
        if (error.response) {
          if(error.response.data && !error.response.data.errors){
            that.$warningAlert(error.response.data.msg)
          }else{
            that.$warningAlert('Something went wrong');
          }
          that.$warningAlert(Object.values(error.response.data.errors),"Errors");
        }
      });
    }, 1200), // 3 сек задержки
    updateSettingsGeneral: debounce(function() {
      let that=this;
      let url = this.url('updateSettings');
      this.settings.tokenPosterPos = that.tokenPosterPos
      this.settings.freedompaySecretKey = that.freedompaySecretKey
      this.settings.freedompayShopId = that.freedompayShopId

      this.settings.smsnikitaLogin = that.smsnikitaLogin
      this.settings.smsnikitaPass = that.smsnikitaPass
      
      if (this.classes.opened) {
        this.settings.onec_on = true;
      } else {
        this.settings.onec_on = false;
      }
      if (this.classes_pos.opened) {
        this.settings.poster_on = true;
      } else {
        this.settings.poster_on = false;
      }
      if (this.classes_fr.opened) {
        this.settings.freedom_on = true;
      } else {
        this.settings.freedom_on = false;
      }
      if (this.classes_smsnikita.opened) {
        this.settings.smsnikita_on = true;
      } else {
        this.settings.smsnikita_on = false;
      }

      console.log("set1", this.settings)

      this.axios.put(url, this.settings).then(function (response) {
        console.log(response);
        
          that.$successAlert('Updated');
      }).catch(function(error){
        if (error.response) {
          if(error.response.data && !error.response.data.errors){
            that.$warningAlert(error.response.data.msg)
          }else{
            that.$warningAlert('Something went wrong');
          }

          that.$warningAlert(Object.values(error.response.data.errors),"Errors");
        }
      });
    }, 1200), // 3 сек задержки
  },
  

  addNewVal(field){
      this[field].values.unshift('')
    },
    removeVal(field, index){
      this[field].values.splice(index, 1)
    },
    created() { // Изменено на mounted
      let that = this;
    this.axios
        .get(this.url('getPersonalSettings'))
        .then(function (response){
          let settings = response.data.user;
          console.log('getPersonalSettings', settings)
          that.oneCApiAddress = settings.oneCApiAddress || '';
          that.oneCApiLogin = settings.oneCApiLogin || '';
          that.oneCApiPassword = settings.oneCApiPassword || '';
          that.id= settings._id || '';
          setTimeout(() => { // Добавили задержку
            that.isInitialized = true
            console.log('isInitialized1', that.isInitialized)
          }, 2000); // Задержка в 2 секунды
        })

    this.axios
        .get(this.url('getSettings'))
        .then(function (response){
          let settings = response.data.object;
          console.log('getSettings', settings)
          that.settings = settings
          that.tokenPosterPos = settings.tokenPosterPos || '';
          that.freedompaySecretKey = settings.freedompaySecretKey || '';
          that.freedompayShopId = settings.freedompayShopId || '';

          that.smsnikitaLogin = settings.smsnikitaLogin || '';
          that.smsnikitaPass = settings.smsnikitaPass || '';

          if(settings.onec_on) {
            that.classes.opened = true;
            that.classes.closed = false;
            that.settings.test_1 = true;
          } else {
            that.classes.opened = false;
            that.classes.closed = true;
            that.settings.test_1 = false;
          }
          if(settings.poster_on) {
            that.classes_pos.opened = true;
            that.classes_pos.closed = false;
            that.settings.test_2 = true;
          } else {
            that.classes_pos.opened = false;
            that.classes_pos.closed = true;
            that.settings.test_2 = false;
          }
          if(settings.freedom_on) {
            that.classes_fr.opened = true;
            that.classes_fr.closed = false;
            that.settings.test_3 = true;
          } else {
            that.classes_fr.opened = false;
            that.classes_fr.closed = true;
            that.settings.test_3 = false;
          }
          if(settings.smsnikita_on) {
            that.classes_smsnikita.opened = true;
            that.classes_smsnikita.closed = false;
            that.settings.test_4 = true;
          } else {
            that.classes_smsnikita.opened = false;
            that.classes_smsnikita.closed = true;
            that.settings.test_4 = false;
          }
          
          setTimeout(() => { // Добавили задержку
            that.isInitialized = true
            console.log('isInitialized2', that.isInitialized)
          }, 2000); // Задержка в 2 секунды
        })
  },
    watch: {
    smsnikitaLogin: function() {
      if (this.isInitialized) { // Добавлено
        this.updateSettingsGeneral();
      }
    },
    smsnikitaPass: function() {
      if (this.isInitialized) { // Добавлено
        this.updateSettingsGeneral();
      }
    },
    tokenPosterPos: function() {
      if (this.isInitialized) { // Добавлено
        this.updateSettingsGeneral();
      }
    },
    freedompaySecretKey: function() {
      if (this.isInitialized) { // Добавлено
        this.updateSettingsGeneral();
      }
    },
    freedompayShopId: function() {
      if (this.isInitialized) { // Добавлено
        this.updateSettingsGeneral();
      }
    },
    oneCApiAddress: function() {
      if (this.isInitialized) { // Добавлено
        this.updateSettings();
      }
    },
    oneCApiLogin: function() {
      if (this.isInitialized) { // Добавлено
        this.updateSettings();
      }
    },
    oneCApiPassword: function() {
      if (this.isInitialized) { // Добавлено
        this.updateSettings();
      }
    },
  }
}


</script>

<style scoped>

.tooltip-box { 
  position: relative;
  display: inline-block;
}


.tooltip-icon:hover + .tooltip {
  opacity: 1;
  pointer-events: auto;
}


.tooltip { 
  color: #838383;
  text-align: center;
  padding: 10px 0;
  border-radius: 3px;
  

  width: 300px;
  bottom: 150%;
  left: 50%;
  margin-left: -20px;
  margin-top: 20px;

  opacity: 0;
  transition: opacity 1s;
  pointer-events: none;

  position: absolute;
  z-index: 1;

  background-color: #ffffff;
  box-shadow: #949090 0px 0px 15px;
}

.text {
  font-size: 14px;
  margin: 15px 0;

}

.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #E6E6E6 transparent transparent transparent;
}

.opened {
  background-color:#616CF52B;
}

.closed {
  background-color:#EFEFEF;
}

.cashback-input{
  width: 100%;
  margin-bottom: 10px;
}

.left-general .cashback-input{
  margin-bottom: 15px;
}
</style>