<template>
  <div>
    <li class="catalog-list" :id="category.name" :ref="'menu' + index"
      :class="{ active: selectedCategory === category._id }" :style="{ 'background-color': backgroundColor }"  @click="onClickCategory(category._id)">
      <div class="category-img-name">
        <div v-if="category.name !== 'all' && settings.category_picture" class="category-image">
          <img v-if="imageLoaded && category.img" :src="imgSrc+'/' + category.img">
          <img v-else-if="imageLoaded" src="../../assets/icons/no-catalog.svg">
          <div v-else class="image-placeholder"></div>
        </div>
        <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top" :title="category.name">
          {{ category.name }}
        </p>
      </div>
      
      <div v-if="category._id" class="dropdown dropMenu">
        <div class="dropdown-toggle" id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <img  src="../../assets/icons/three-dots.svg">
        </div>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu">
          <ul class="list-group">
            <li class="list-group-item" data-toggle="modal" data-target="#edit-category"
              @click="selectCategoryWrapper(category._id)">Edit</li>
            <li class="list-group-item" @click.stop.prevent="deleteCategoryWrapper(category._id)">Delete</li>
          </ul>
        </div>
      </div>
      <div v-else class="dropdown dropMenu">
        <div class="dropdown-toggle" id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <img  src="../../assets/icons/filter.svg">
        </div>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu">
          <ul class="list-group">
            <li class="list-group-item" data-toggle="modal" data-target="#arrangement-category"
              @click="selectCategoryWrapper(category._id)">Edit arrangement</li>
          </ul>
        </div>
      </div>
    </li>
    <div v-if="category.children && category.children.length > 0" :style="{ 'padding-left': '15px' }">
      <CategoryItem 
        v-for="(child, childIndex) in visibleChildren" 
        :key="child._id" 
        :category="child" 
        :index="index + '_' + childIndex"
        :selectedCategory="selectedCategory" 
        @setCategory="emitSetCategory" 
        @selectCategory="emitSelectCategory" 
        @deleteCategory="emitDeleteCategory" 
      />
    </div>
  </div>
</template>


<script>
export default {
  name: 'CategoryItem',
  props: {
    category: Object,
    index: [String, Number],
    selectedCategory: String
  },

  data(){
    return {
      imgSrc:'',
      settings: {},
      imageLoaded: false,
      visibleChildrenCount: 5,
    }
  },

  computed: {
    backgroundColor() {
      // Получаем уровень вложенности по индексу
      const level = this.index.toString().split('_').length;

      // Если уровень 0 (родительский), возвращаем базовый белый цвет
      if (level === 1) {
        return 'rgb(255, 255, 255)'; // Белый цвет без затемнения
      }

      // Базовый серый цвет
      const baseColor = 245; // RGB(240, 240, 240)
      
      // Коэффициенты затемнения для каждого уровня
      let darkenFactor = 0;
      switch (level) {
        case 2: 
          darkenFactor = 1; 
          break;
        case 3: 
          darkenFactor = 15; 
          break;
        case 4: 
          darkenFactor = 35; 
          break;
        case 5: 
          darkenFactor = 45; 
          break;
        default: 
          darkenFactor = 65 + (level - 5) * 20; // Для уровней выше 4 увеличиваем затемнение линейно
      }

      const newColor = baseColor - darkenFactor;

      // Ограничиваем минимальное значение цвета, чтобы не уходить в негативное значение
      const finalColor = Math.max(newColor, 0);

      return `rgb(${finalColor}, ${finalColor}, ${finalColor})`;
    },
    
    visibleChildren() {
      return this.category.children ? this.category.children.slice(0, this.visibleChildrenCount) : [];
    },
  },

  methods: {
    onClickCategory(id) {
      console.log("onClickCategory", id);
      this.$emit('setCategory', id);
    },
    selectCategoryWrapper(id) {
      this.$emit('selectCategory', id);
    },
    deleteCategoryWrapper(id) {
      this.$emit('deleteCategory', id);
    },
    emitSetCategory(id) {
      this.$emit('setCategory', id);
    },
    emitSelectCategory(id) {
      this.$emit('selectCategory', id);
    },
    emitDeleteCategory(id) {
      this.$emit('deleteCategory', id);
    },
    
    preloadAllImages() {
      const preloadImage = (src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = resolve;
          img.onerror = reject;
          img.src = src;
        });
      };

      const imagesToPreload = [
        this.category.img ? this.imgSrc + '/' + this.category.img : null,
        '../../assets/icons/no-catalog.svg'
      ].filter(Boolean);

      Promise.all(imagesToPreload.map(preloadImage))
        .then(() => {
          this.imageLoaded = true;
        })
        .catch(error => {
          console.error('Ошибка при предзагрузке изображений:', error);
          this.imageLoaded = true; // Устанавливаем в true даже в случае ошибки, чтобы показать хоть что-то
        });
    },
    
    loadMoreChildren() {
      this.visibleChildrenCount += 5;
    },
    
    handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500) {
        this.loadMoreChildren();
      }
    },
  },

  mounted() {
    this.imgSrc = this.$server;
    this.preloadAllImages();
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeCreate(){
    let that = this;
    this.axios
        .get(this.url('getSettings'))
        .then(function (response){
          let settings = response.data.object;
          that.settings = settings;
        })
  },
};
</script>

<style scoped>

.imgVisible{
  opacity: 0.5;
}

.category-image {
  margin-right: 8px; /* Добавляем отступ справа от изображения */
}

.category-image img {
  border-radius: 50%;
  width: 35px; /* Ширина изображения */
  height: 35px; /* Высота изображения */
}

.catalog-list-child {
  background-color: #F4F4F4;
  padding-left: 10px;
}

.catalog-list-child-nested {
  background-color: #D1D1D1;
  padding-left: 20px;
}

.category-img-name {
  display: flex;
  align-items: center;
  list-style-type: none;
  border: none;
  justify-content: space-between;
  max-width: 80%;
}

.catalog-list {
  list-style-type: none;
  border: none;
  height: 45px;
  border-radius: 5px;
  padding: 0 10px;
  padding-right: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.active {
  background-color: #E9ECFF;
  color: #616CF5;
}

.category-text {
  max-width: 100%; /* Убедитесь, что элемент не выходит за пределы родительского контейнера */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-placeholder {
  width: 35px;
  height: 35px;
  background-color: #f0f0f0;
  border-radius: 50%;
}
</style>
