<template>
  <div class="login-container">
    <div class="login-column">
      <div class="rounded-top-block">
        <img src="../../assets/img/new_mini_logo.png" alt="Logo" class="logo">
        <div class="toggle-buttons">
          <button 
            :class="['toggle-btn', { active: activeTab === 'login' }]" 
            @click="setActiveTab('login')"
          >
            Login
          </button>
          <button 
            :class="['toggle-btn', { active: activeTab === 'register' }]" 
            @click="setActiveTab('register')"
          >
            Register
          </button>
        </div>
      </div>
      
      <div v-if="catalog_settings.card && catalog_settings.card.on">
        <p v-if="isAddingCard" class="login-description">
          Войдите для получения цифровой карты лояльности для электронного кошелька
        </p>
        <p v-else class="login-description">
          Войдите для просмотра своих заказов и участия в программе лояльности
        </p>

        <transition name="fade" mode="out-in">
        <form v-if="activeTab === 'login'" @submit.prevent="login">
          <div class="form-group-this">
            <input v-model="email" id="email" type="email" placeholder="Email" required>
          </div>
          <div class="form-group-this">
            <div class="password-input">
              <input v-model="password" id="password" :type="showPassword ? 'text' : 'password'" placeholder="Password" required>
              <img 
                @click="togglePassword" 
                :src="showPassword ? require('../../assets/icons/Hide.svg') : require('../../assets/icons/eye.svg')"
                alt="Toggle password visibility"
              >
            </div>
          </div>
          <div class="form-options">
            <label class="remember-me">
              <input type="checkbox" v-model="rememberMe">
              <span>Stay signed in</span>
            </label>
            <a href="#" @click.prevent="openForgotPasswordModal">Forgot your password?</a>
          </div>
          <button type="submit" class="submit-btn">Sign In</button>
        </form>

        <form v-else @submit.prevent="register">
          <div v-if="catalog_settings.card.auth_fields.includes('name')" class="form-group-this">
            <input v-model="name" id="name" type="text" placeholder="Name" required>
          </div>
          <div class="form-group-this">
            <input v-model="email" id="email" type="email" placeholder="Email" required>
          </div>
          <div v-if="catalog_settings.card.auth_fields.includes('phone')" class="form-group-this">
            <input v-model="phone" id="phone" type="text" placeholder="Phone" required>
          </div>

          <div v-if="catalog_settings.customFields" class="form-group-this">
            <div v-for="(field, key) in customFields" :key="key">
              <div v-if="catalog_settings.card.auth_fields.includes(`${key}`)">
                <input v-if="field.values.length === 0 || (field.values.length === 1 && field.values[0] === '')" 
                        v-model="customFieldsData[key]" 
                        :name="key"
                        class="login-input" 
                        :placeholder="field.fieldName" 
                        :required="field.required">
                <select v-else 
                        v-model="customFieldsData[key]" 
                        :name="key"
                        class="login-input" 
                        
                        :required="field.required">
                        <option value="" disabled hidden>{{ field.fieldName }}</option>
                  <option v-for="value in field.values" :key="value" :value="value">{{ value }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="form-group-this">
            <div class="password-input">
              <input v-model="password" id="password" :type="showPassword ? 'text' : 'password'" placeholder="Password" required>
              <img 
                @click="togglePassword" 
                :src="showPassword ? require('../../assets/icons/Hide.svg') : require('../../assets/icons/eye.svg')"
                alt="Toggle password visibility"
              >
            </div>
          </div>
          <button type="submit" class="submit-btn">Sign Up</button>
        </form>
        </transition>
        <!-- <div class="social-login">
        <p>Or sign in with:</p>
        <div class="social-icons-this">
          <img src="../../assets/icons/facebook.svg" alt="Facebook">
          <img src="../../assets/icons/google.svg" alt="Google">
        </div>
      </div> -->
      </div>
      <div v-else>
        <p class="login-description">
          Доступ к выпуску новых карт временно отключен администраторами, зайдите, пожалуйста, позже.
        </p>
      </div>
      

      
    </div>

    <!-- Forgot Password Modal -->
    <!-- Forgot Password Modal -->
    <div v-if="showForgotPassword" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeForgotPasswordModal">&times;</span>
        <h2>Forgot Password</h2>
        <div v-if="notification" :class="['notification', notification.type]">
          {{ notification.message }}
        </div>
        <div v-if="!codeSent">
          <form @submit.prevent="sendResetPasswordEmail">
            <div class="form-group-this">
              <input 
                v-model="resetEmail" 
                name="reset-email" 
                class="login-input" 
                placeholder="Email"
                type="email"
                required
                :class="{ 'invalid': !isValidEmail(resetEmail) && resetEmail !== '' }"
              >
            </div>
            <button class="submit-btn" type="submit" :disabled="!isValidEmail(resetEmail)">Send Code</button>
          </form>
        </div>
        <div v-else>
          <form @submit.prevent="verifyCodeAndResetPassword">
            <div class="form-group-this">
              <input 
                v-model="verificationCode" 
                name="verification-code" 
                class="login-input" 
                placeholder="Verification Code"
                required
                :class="{ 'invalid': !isValidCode(verificationCode) && verificationCode !== '' }"
              >
            </div>
            <div class="form-group-this">
              <input 
                v-model="newPassword" 
                name="new-password" 
                class="login-input" 
                type="password" 
                placeholder="New Password"
                required
                :class="{ 'invalid': !isValidPassword(newPassword) && newPassword !== '' }"
              >
            </div>
            <button class="submit-btn" type="submit" :disabled="!isValidCode(verificationCode) || !isValidPassword(newPassword)">Reset Password</button>
          </form>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
// import $ from "jquery";

export default {
  name: "SignIn",
  data() {
    return {
      activeTab: 'login',
      showPassword: false,
      rememberMe: false,

      catalog_settings: {},
      
      customFieldsData: {
         custom_field_0: '',
         custom_field_1: '',
         custom_field_2: '',
         // Добавьте остальные поля по необходимости
       },

       notification: null,

      name: '',
      phone: '',
      email: '',
      password: '',
      showForgotPassword: false,
      resetEmail: '',
      verificationCode: '',
      newPassword: '',
      codeSent: false
    }
  },
  computed: {
    currentCompanyCatalog() {
      return this.$route.params.bekon;
    },
    isAddingCard() {
      return new URLSearchParams(window.location.search).get('c') === '1';
    },
    signUpLink() {
      let link = `/${this.currentCompanyCatalog}/signup`;
      if (this.isAddingCard) {
        link += '?c=1';
      }
      return link;
    },
    customFields() {
      const fields = {};
      for (let i = 0; i < 3; i++) {
        const key = `custom_field_${i}`;
        if (this.catalog_settings[key] && this.catalog_settings[key].fieldName) {
          fields[key] = this.catalog_settings[key];
        }
      }
      return fields;
    },
  },
  methods: {

    showNotification(message, type) {
      this.notification = { message, type };
      setTimeout(() => {
        this.notification = null;
      }, 3000); // Уведомление исчезнет через 3 секунды
    },
    showSuccessNotification(message) {
      this.showNotification(message, 'success');
    },
    showErrorNotification(message) {
      this.showNotification(message, 'error');
    },

    isValidEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    isValidCode(code) {
      return code.length === 6 && /^\d+$/.test(code);
    },
    isValidPassword(password) {
      return password.length >= 8;
    },

    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    // showPassword: function () {
    //   var x = document.getElementById("password");
    //   if (x.type === "password") {
    //     x.type = "text";
    //     $('#hide-eye').css({ 'display': 'none' })
    //     $('#show-eye').css({ 'display': 'block' })
    //   } else {
    //     x.type = "password";
    //     $('#show-eye').css({ 'display': 'none' })
    //     $('#hide-eye').css({ 'display': 'block' })
    //   }
    // },
    login() {
      let that = this;
      const options = {
        headers: { "x-client-url": this.currentCompanyCatalog }
      }
      let url = this.url('loginClient');
      let data = {
        email: this.email,
        password: this.password,
      }
      this.axios.post(url, data, options).then(function (response) {
        that.$successAlert('Logged in!');
        that.$store.dispatch("Client/setUserAuth", response.data);
        localStorage.setItem('token', response.data.token);
        
        const userId = response.data.object._id;


        // Проверяем наличие параметра c=1 в URL
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('c') === '1') {
          that.$router.push({
            name: 'AddCard',
            query: { 
                userId: userId,
                currentCompanyCatalog: that.currentCompanyCatalog
            }
          });
        } else {
          // Если параметра нет, перенаправляем на стандартную страницу аккаунта
          that.$router.push({ path: `/${that.currentCompanyCatalog}/client-account` });
        }
        
      }).catch(function (error) {
        if (error.response) {
          that.$warningAlert(Object.values(error.response.data.errors).join(', '));
        }
      });
    },
    register(){
      let that=this;
      const options = {
        headers: {"x-client-url": this.currentCompanyCatalog}
      }
      let url = this.url('registerClient');
      let data = {
        name:this.name,
        phone:this.phone,
        email:this.email,
        password:this.password,
        ...this.customFieldsData
      }
      this.axios.post(url,data,options).then(function (response) {
        console.log(response);
        that.$successAlert('Registered');
        that.$store.dispatch("Client/setUserAuth",response.data);
        localStorage.setItem('token', response.data.token);
        // that.$router.go(-1);

        const userId = response.data.object._id;
        // Проверяем наличие параметра c=1 в URL
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('c') === '1') {
          that.$router.push({
            name: 'AddCard',
            query: { 
                userId: userId,
                currentCompanyCatalog: that.currentCompanyCatalog
            }
          });
        } else {
          // Если параметра нет, перенаправляем на стандартную страницу аккаунта
          that.$router.push({ path: `/${that.currentCompanyCatalog}/client-account` });
        }
      }).catch(function(error){
        if (error.response) {
          that.$warningAlert(Object.values(error.response.data.errors))
          // console.log(error.response.status);
          // console.log(error.response.headers);
          console.log(Object.values(error.response.data.errors));
          // that.displayMessages(Object.values(error.response.data.errors),"Errors");
        }
      });
    },
    openForgotPasswordModal() {
      this.showForgotPassword = true;
    },
    closeForgotPasswordModal() {
      this.showForgotPassword = false;
      this.resetEmail = '';
      this.verificationCode = '';
      this.newPassword = '';
      this.codeSent = false;
    },
    sendResetPasswordEmail() {
      const options = {
        headers: {"access-place": this.currentCompanyCatalog, "accept-language": "en"}
      }
      let url = this.url('resetPasswordMessage');
      this.axios.post(url, { email: this.resetEmail }, options)
        .then(() => {
          this.showSuccessNotification('Check your email for the verification code');
          this.codeSent = true;
        })
        .catch(() => {
          this.showErrorNotification('Failed to reset password');
        });
    },
    verifyCodeAndResetPassword() {
      const checkCodeOptions = {
        headers: {"access-place": this.currentCompanyCatalog, "accept-language": "en"}
      }
      let url = this.url('resetCheckCode');
      this.axios.post(url, { email: this.resetEmail, code: this.verificationCode, new_password: this.newPassword }, checkCodeOptions)
        .then(() => {
          // Check if code is correct
          this.showSuccessNotification('Password updated successfully');
          setTimeout(() => {
            this.closeForgotPasswordModal();
          }, 3000);
        })
        .catch(() => {
          this.showErrorNotification('Invalid verification code');
        });
    },
    resetPassword() {
      const resetOptions = {
        headers: {"access-place": this.currentCompanyCatalog, "accept-language": "en"}
      }
      let url = this.url('resetPassword');
      this.axios.post(url, { email: this.resetEmail, code: this.verificationCode, password: this.newPassword }, resetOptions)
        .then(() => {
          this.$successAlert('Password reset successfully.');
          this.closeForgotPasswordModal();
        })
        .catch(() => {
          console.log('Attempting to show error alert');
          this.$errorAlert('Failed to reset password.');
        });
    }
  },
  mounted() {
    const routerContent = document.querySelector('.router-content');
    if (routerContent) {
      routerContent.style.marginTop = '0';
    }
  },
  beforeCreate() {
    (async () => {
      const options = {
        headers: {"x-client-url": this.$route.params.bekon}
      }

      let that = this;

      await this.axios.get(this.url('getCatalogSettings'), options)
        .then((response) => {
          let settings = response.data.object;
          that.catalog_settings = settings;
        }).catch(function (error){
          if (error.response) {
            console.log('e1', error.response)
          }
        })
    })().catch(err => {
      console.error(err);
    });
  },
}
</script>

<style scoped>

.notification {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 14px;
  text-align: center;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}


.modal-content {
  position: relative;
  padding: 30px;
}

.modal-content h2 {
  font-size: 1.5em;
  margin-bottom: 40px;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
}

.form-group-this {
  margin-bottom: 15px;
}

.login-input.invalid {
  border: 1px solid red;
}

.submit-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}



.add-card-notice {
  color: #616CF5;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
  border-radius: 10px;
  text-align: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.authorization{
  margin-top: -90px;
}
.login{
  width: 586px;
  background: #FFFFFF;
  border: 1px solid #D3D3D3;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 50px auto;
  padding:35px 90px;
  text-align: center;


}
.sign-in-btn{
  margin-bottom: 24px;
}
form{
  text-align: left;
}
.label{
  color:#222;
  margin-bottom: 8px;
  margin-right: 10px;
}
.remind{
  margin-bottom: 60px;
  color:#858585;
}
.remind a, .have-account a{
  color: #616CF5;
  text-decoration: none;
}
.remind label{
  margin-right: 10px;
}

.have-account{
  font-size: 14px;
  color:#b0b0b0;
  margin-bottom: 40px;
}
.social-link img{
  margin-right: 40px;
}
.social-link img:last-child{
  margin-right: 0;
}

.welcome-sign-in{
  font-size:48px;
  font-weight: bold;
  color: #616CF5;
  line-height: 100%;
  margin-bottom: 38px;
}
.welcome-sign-in span{
  font-weight: 100;
}

.or-div{
  height: 0;
  width: 130px;
  border-bottom:1px solid #b0b0b0;
}
.or-text{
  margin:0 20px;
  color:#b0b0b0;
  font-size:18px;

}
.main-or{
  margin-top:15px;
  margin-bottom: 15px;
}

@media(max-width:768px){
  .login{
    width: 100%;
    padding: 0;
    border:none;
    margin: 0;
  }
  .authorization{
    background: #fff;
    padding: 0;
  }
  .login-container {
    min-height: auto;
  }
}


.login-input{
  width: 100%;
  padding: 12px;
  border: 0px solid #ddd;
  border-radius: 20px;
  background: #F9F9F9;
  font-size: 14px;
}



input[type="checkbox"] {
  appearance: auto;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
}

.login-column {
  transition: height 1s ease;
  width: 100%;
  max-width: 400px;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.rounded-top-block {
  background: #DCDEF9;
  padding: 30px 20px;
  text-align: center;
  border-radius: 10px 10px 0 0;
}

.logo {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.toggle-buttons {
  display: flex;
  justify-content: space-between;
  background: #F5F5F5;
  border-radius: 20px;
  padding: 5px;
  height: 47px;
}

.toggle-btn {
  flex: 1;
  border: none;
  background: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.toggle-btn.active {
  background: #FFFFFF;
}

.login-description {
  text-align: center;
  color: #707070;
  padding: 20px 20px 0px 20px;
  font-size: 14px;
}

form {
  padding: 0px 20px 20px 20px;
}

.form-group-this {
  margin-bottom: 10px;
}

input[type="email"],
input[type="password"],
input[type="text"] {
  width: 100%;
  padding: 12px;
  border: 0px solid #ddd;
  border-radius: 20px;
  background: #F9F9F9;
  font-size: 14px;
}

input::placeholder {
  color: #999;
}

.password-input {
  position: relative;
}

.password-input img {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 5px 20px 5px;
  font-size: 14px;
}

.remember-me {
  display: flex;
  align-items: center;
}

.remember-me input {
  margin-right: 5px;
}

label {
  margin-bottom: 0px;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background: #616CF5;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;
}

.social-login {
  text-align: center;
  padding: 20px;
  border-top: 1px solid #eee;
}

.social-icons-this {
  display: flex;
  justify-content: center; /* Центрируем иконки */
  align-items: center;
  margin-top: 10px;
  padding: 0;
  list-style-type: none;
  width: auto;
}

.social-icons-this img {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: block;
  margin: 0 10px; /* Уменьшаем расстояние между иконками */
}

@media (max-width: 768px) {
  .login-container {
    display: block;
    padding: 0;
    background-color: #FFFFFF;
  }

  .login-column {
    max-width: 100%;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
  }

  .rounded-top-block {
    border-radius: 0;
    border-radius: 20px;
  }

  .toggle-buttons {
    margin: 0 20px;
  }

  form {
    padding: 20px;
  }

  .social-login {
    padding: 20px;
  }

  .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

  /* Добавьте эти стили */
  body, html {
    height: auto;
    min-height: 100%;
    scroll-behavior: smooth;
  }
}

</style>