<template>
    <div class="add-card-or-catalog">
      <div class="button-container">
        <img v-if="isAndroid || isDesktop" @click="addCardAndroid" class="action-img" src="../../assets/img/googlewallet-btn.svg">
        <img v-if="isIOS || isDesktop" @click="addCard" class="action-img" src="../../assets/img/applewallet-btn.png">

        <hr class="divider">

        <button class="action-button" style="border-radius: 25px;" @click="goToCatalog">Перейти в каталог</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AddCard',
    props: {
      userId: {
        type: String,
        required: true
      },
      currentCompanyCatalog: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isIOS: false,
        isAndroid: false,
        isDesktop: false
      }
    },
    methods: {
      detectDevice() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          this.isIOS = true
        } else if (/android/i.test(userAgent)) {
          this.isAndroid = true
        } else {
          this.isDesktop = true
        }
      },
      addCard() {
        this.$router.push(`/v1/createCard?id=${this.userId}&cat_url=${this.currentCompanyCatalog}`).then(() => {
            window.location.reload();

            //wait 5 sec and redirect to catalog
            setTimeout(() => {
                this.$router.push(`/${this.currentCompanyCatalog}/client-account`);
            }, 5000);
        });
      },
      goToCatalog() {
        this.$router.push(`/${this.currentCompanyCatalog}/client-account`);
      },
      addCardAndroid() { // Новый метод
        try {
            const checkCodeOptions = {
                headers: {"access-place": this.currentCompanyCatalog, "accept-language": "en"}
            }
            let url = this.url(`createCardAndroid?id=${this.userId}&cat_url=${this.currentCompanyCatalog}`);
            this.axios.get(url, checkCodeOptions)
                .then((data) => {
                    console.log(data);
                    if (data.data.url) {
                        window.open(data.data.url, '_blank');
                    } else {
                        console.error('URL not found in response');
                    }
                })
                .catch(() => {
                    this.$errorAlert('Failed to reset password.');
                });
            
        } catch (error) {
            console.error('Error adding card to Google Wallet:', error);
        }
      }
    },
    mounted() {
      this.detectDevice()
    },
  }
  </script>
  
  <style scoped>
  .divider {
    width: 80%;
    border: none;
    border-top: 1px solid #e0e0e0;
    margin: 15px auto;
  }

  .add-card-or-catalog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #FFFFFF;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .action-button {
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background-color: #616CF5;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .action-img {
    max-width: 212px;
    margin-left: 5px;
  }
  
  .action-button:hover {
    background-color: #4A54E8;
  }
  
  @media (max-width: 768px) {
    .action-button {
      padding: 12px 24px;
      font-size: 16px;
    }

    .divider {
      width: 80%;
      border: none;
      border-top: 1px solid #e0e0e0;
      margin: 5px auto;
    }

    .action-img {
      max-width: 178px;
      margin-left: 5px;
    }

    .button-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 10%;
    }
  }
  </style>