<template>

  <div class="settings">
      <SettingsNavbar/>
      <router-view/>

  </div>
</template>

<script>
import SettingsNavbar from "@/components/settings/SettingsNavbar";
export default {
name: "Settings",
  components:{
  SettingsNavbar
  },

  methods: {
    getDaysLeft(selectedDate){
      let today = this.$moment();
      let start = this.$moment(selectedDate).startOf('day');
      let end = this.$moment(today).startOf('day');
      return start.diff(end, 'days',true);
    },
    checkAccess1(){
      this.user_info = JSON.parse(localStorage.getItem('user'))
      let days_left = this.getDaysLeft(this.user_info.activeBefore)
      console.log("days_left", days_left)
      if (days_left <= 0) {
        this.$router.push({name: 'Price'})
      }
    }
  },

  mounted() {
    this.checkAccess1();
  }

}
</script>

<style scoped>

.settings{
  padding:0 30px;

}
</style>