<template>
    <div class="related-products">
      <h3 class="related-title">Related Products</h3>
      <div class="product-carousel-wrapper">
        <img src="../../../assets/clients/arrow-right.svg" class="carousel-arrow left" @click="scrollProducts('left')">
        <div class="product-carousel">
          <div class="carousel-container" ref="carouselContainer">
            <div class="col-lg-3 col-6 product-box" :id="`catalog${product._id}`" v-for="product in relatedProducts" :key="product._id">
              <div>
                <div @click="$router.push({ path:`/${currentCompanyCatalog}/catalog-detail/${product._id}`})" class="product-img">
                  <img v-if="!product.error" :src="server+'/'+product.img" @error="product.error=true">
                  <img v-else src="../../../assets/icons/noProd.png">
                </div>
                <div class="product-add">
                  <h2 class="long-text tool-tip" style="overflow: hidden;text-overflow: ellipsis; margin-bottom:10px;"
                      data-toggle="tooltip" data-placement="top" :title="product.name">{{product.name}}</h2>
                  <span class="mr-2" v-if="checkDates(product.promoStart,product.promoEnd)">{{product.promoPrice}} {{catalog_settings.currency}}</span>
                  <span :class="{lineThrough:checkDates(product.promoStart,product.promoEnd)}">{{product.price}} {{catalog_settings.currency}}</span>
                </div>
                <button class="add-to-card" @click="selectProduct(product._id)">Add to cart</button>
              </div>
            </div>
          </div>
        </div>
        <img src="../../../assets/clients/left-arrow.svg" class="carousel-arrow right" @click="scrollProducts('right')">
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'RelatedProducts',
    props: {
      categoryId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        relatedProducts: [],
        today: new Date()
      }
    },
    computed: {
      currentCompanyCatalog() {
        return this.$route.params.bekon;
      },
      server() {
        return this.$server;
      },
      catalog_settings() {
        return this.$store.getters['Catalog/getCatalog_settings'];
      }
    },
    methods: {
      async fetchRelatedProducts() {
        try {
          const options = {
            headers: {"X-Client-Url": this.$route.params.bekon}
          }
          console.log("catalog_Settings data", this.catalog_settings)
          let that = this;
          this.axios.get(this.url(`getClientProducts/?page=1&categoryId=${this.categoryId}&min=0&max=1000&searchText=&sortBy=ascendingName`), options).then(function(response) {
            console.log("Settings res get", response.data);
            that.relatedProducts = response.data.objects;
          }).catch(function(error) {
            console.error("Ошибка при загрузке настроек:", error);
          });
          
          // const response = await fetch(`http://localhost:8085/getClientProducts/?page=1&categoryId=${this.categoryId}&min=0&max=1000&searchText=&sortBy=ascendingName`);
          // const data = await response.json();
          // this.relatedProducts = data.products;
        } catch (error) {
          console.error('Error fetching related products:', error);
        }
      },
      checkDates(start, end) {
        if (!start || !end) return false;
        let startDate = new Date(start);
        let endDate = new Date(end);
        return startDate <= this.today && endDate >= this.today;
      },
      selectProduct(id) {
        this.$router.push({ path: `/${this.currentCompanyCatalog}/catalog-detail/${id}` });
      },
      scrollProducts(direction) {
        const container = this.$refs.carouselContainer;
        const scrollAmount = 200;
        if (direction === 'left') {
          container.scrollLeft -= scrollAmount;
        } else {
          container.scrollLeft += scrollAmount;
        }
      }
    },
    watch: {
      categoryId: {
        immediate: true,
        handler() {
          this.fetchRelatedProducts();
        }
      }
    }
  }
  </script>
  
<style scoped>
.related-products {
  margin-top: 40px;
  padding: 20px 0;
}

.related-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.add-padding {
  padding-right: 15px;
}

.long-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-img {
  margin-bottom: 16px;
  height: 176px;
}

.product-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-add {
  max-width: 100%;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
}

.add-to-card {
  color: #898989;
  font-size: 14px;
  border: none;
  padding: 0.3rem 2rem;
  background: #F4F4F4;
  border-radius: 10px;
  width: 140px;
}

.add-to-card:hover {
  color: #2762D3;
  background: #ffffff;
  transition: .3s;
  border: 1px solid #2762D3;
}
.product-box {
text-align: center;
cursor: pointer;
padding: 15px;
box-sizing: border-box;
border: 0.5px solid transparent;
padding-bottom: 20px;
max-width: 200px;
margin: 0 auto;
}
.product-box:hover {
border: 0.5px solid rgba(0, 0, 0, 0.2);
box-sizing: border-box;
border-radius: 5px;
transition: 0.3s;
}
.product-add span {
font-size: 16px;
}
.product-add h2 {
margin-bottom: 5px;
}
.product-add h2, .product-add h3 {
font-size: 14px;
}
.product-add h3 {
font-weight: normal;
color: #b0b0b0;
margin: 5px 0;
}
.lineThrough {
text-decoration: line-through;
font-size: 12px !important;
font-weight: normal;
}
.product-carousel-wrapper {
position: relative;
width: 100%;
margin-bottom: 20px;
margin-top: 20px;
}
.product-carousel {
width: 100%;
position: relative;
margin-bottom: 20px;
padding: 0 1px;
}
.carousel-container {
display: flex;
overflow-x: auto;
scroll-behavior: smooth;
-ms-overflow-style: none;
scrollbar-width: none;
}
.carousel-container::-webkit-scrollbar {
display: none;
}
.carousel-arrow {
position: absolute;
top: 33%;
transform: translateY(-50%);
width: 30px;
height: 30px;
cursor: pointer;
z-index: 1;
}
.carousel-arrow.left {
left: -40px;
}
.carousel-arrow.right {
right: -40px;
}
@media (max-width: 768px) {
.carousel-arrow {
display: none;
}
.product-box {
padding-right: 0;
}
.product-box:hover {
border: none;
}
}
@media (max-width: 576px) {
.product-box:hover {
border: none;
}
.product-box {
padding-right: 0;
}
}
@media (max-width: 380px) {
.product-img img {
height: 170px;
}
.product-img {
margin-bottom: 0;
}
.add-to-card {
padding: 0.3rem 30px;
}
}
@media (max-width: 330px) {
.product-img img {
height: 138px;
}
}
  </style>