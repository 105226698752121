<template>
<div class="container" style="padding-bottom: 20px;">
    <div class="row catalog">
        <div id="categories" class="pt-3 col-lg-3 col-md-4">
          <div class="catalog-left">
            <h1 class="product-title">Catalog</h1>
            <h3 class="price">Sort:</h3>
      
            <div class="selectWrapper" style="display:flex;flex-direction: column;position: relative">
              <div @click="showSortDrop=!showSortDrop" style="display: flex;justify-content: space-between">
                <div>{{sortBy}}</div>
                <div>
                  <img v-bind:style="[showSortDrop? {transform:'rotate(180deg)'}:'']" src="../../../assets/img/select_arrow.svg">
                </div>
              </div>
      
              <div class="sortDropDown" v-if="showSortDrop">
                <div @click="showSortDrop=false;" style="float: right;margin-top:-5px;">x</div>
                <div @click="sortBy='ascendingName';getProducts();showSortDrop=false;">Name A-Z</div>
                <div @click="sortBy='descendingName';getProducts();showSortDrop=false;">Name Z-A</div>
                <div @click="sortBy='ascendingPrice';getProducts();showSortDrop=false;">Price 0-100</div>
                <div @click="sortBy='descendingPrice';getProducts();showSortDrop=false;">Price 100-0</div>
              </div>
            </div>
            <hr/>
            <h3 class="price">Price:</h3>
          <div class="d-flex mb-5">
            <div style="width:50%" class="mr-3">
              <label class="label-client">From</label>
              <input class="client-input" v-model="from" style="width:100%">
            </div>
            <div style="width:50%">
              <label class="label-client">To</label>
              <input class="client-input" v-model="to" style="width:100%">
            </div>
          </div>
          <input type="text" id="range-slider" name="example_name" value="" class="d-none"/>
          <div class="line"></div>
          <div class="sticked-categories" style="height: 300px;overflow: auto;">
            <h3 class="price">Categories:</h3>
            <ul class="list-group">
              <li
                v-for="category in listCategory" 
                :key="category._id" 
                :class="{active: category._id === selectedCategory}" 
                class="catalog-list" 
                @click="setCategory(category._id)"
                :style="{ paddingLeft: `${10 + category.level * 20}px` }">
                {{category.name}}
              </li>
            </ul>
          </div>
          </div>
          <div class="mobile-btns">
            <button  class="save" @click="showFilterCategory('category')" ><img class="list-icon" src="../../../assets/icons/list.svg"><span>Select categories</span></button>
            <div class="showCategory">
              <div class="mobile-header d-flex justify-content-between align-items-center" >
                <h3 class="filter-category">Categories</h3>
                <img @click="removeFilterCategory('category')" class="close-nav" src="../../../assets/icons/xBlack.svg">
              </div>
             <div style="height:calc(100% - 82px); overflow-y:auto">
               <ul class="list-group p-0">
                 <li v-for="category in listCategory" :key="category._id" :class="{active: category._id === selectedCategory}" class="catalog-list" @click="setCategory(category._id)">{{category.name}}</li>
               </ul>
             </div>
            </div>
            <div class="d-flex mb-3">
              <button class="app-buttons-item" @click="showFilterCategory('filter')"><img src="../../../assets/icons/filter-btn.svg"><span>Filters</span></button>
            </div>
            <div class="showFilter" >
              <div class="mobile-header d-flex justify-content-between align-items-center" >
                <h3 class="filter-category">Filter</h3>
                  <img @click="removeFilterCategory('filter')" class="close-nav" src="../../../assets/icons/xBlack.svg">
              </div>
              <div class="d-flex mb-5">
                <div style="width:40%" class="mr-3">
                  <label class="label-client">From</label>
                  <input class="client-input" v-model="from" style="width:100%">
                </div>
                <div style="width:40%">
                  <label class="label-client">To</label>
                  <input class="client-input" v-model="to" style="width:100%">
                </div>
              </div>
              <input class="d-none" type="text" id="range-slider2" name="example_name" value="" />
      <!--        <button class=" show save" @click="removeFilterCategory('filter')">Show</button>-->
            </div>
            <div class="category-key">Category: <span class="category-value">{{showCategory}}</span></div>
            <div class="backdrop-filter"></div>
          </div>
        </div>
      
        <div  id="products" class="pt-3 col-lg-9 col-md-8">
          <div style="position: relative;">
            <div class="catalogSearchInputIcon">
              <img @click="catalogSearch" src="../../../assets/icons/search-icon.svg">
            </div>
            <input placeholder="search..." v-model="searchText" @keyup.enter="catalogSearch" class="catalogSearchInput" type="text">
            <div class="catalogSearchClearIcon" v-if="searchText!==''">
              <img @click="searchText='';getProducts()" src="../../../assets/icons/xBlack.svg">
            </div>
          </div>
      
          <div v-if="filteredList.length === 0 && searchText !=='' && searchStatus">
            <div style="display: flex;justify-content: center;align-items: center;">
              No results found for {{searchText}}
            </div>
          </div>
      
          <div v-if="filteredList.length !== 0 && searchText !=='' && searchStatus">
            <div style="display: flex;justify-content: center;align-items: center;">
              Results for: {{searchText}}
            </div>
          </div>
      
          <div v-if="spinner">
            <Spinner/>
          </div>
          <div v-else>
            <ClientCatalogItemPage v-bind:catalog="filteredList"/>
          </div>
      
          <div style="display: flex;height: 50px;width: 100%;justify-content: center;align-items: center;">
            <div v-if="currentPage>3"  @click="currentPage--" style="cursor: pointer;">
              <img src="../../../assets/icons/prevArrow.svg">
            </div>
            <div class="paginationItem" v-if="currentPage>3"  @click="currentPage=1;getProducts()" style="cursor: pointer;">1</div>
            <div v-if="currentPage>3">
              ...
            </div>
            <div v-bind:class="{ activePage: currentPage===page2 }" class="paginationItem" v-for="page2 in numberOfPagesArray.filter(num => num < currentPage+3 && num > currentPage-3)" :key="page2" @click="setPage(page2)">
              {{page2}}
            </div>
            <div v-if="currentPage<numberOfPagesArray.length-2">
              ...
            </div>
            <div class="paginationItem" v-if="currentPage<numberOfPagesArray.length-2" @click="currentPage=numberOfPagesArray.slice(-1)[0];getProducts()" style="cursor: pointer;">{{numberOfPagesArray.slice(-1)[0]}}</div>
            <div v-if="currentPage<numberOfPagesArray.length-2" @click="currentPage++" style="cursor: pointer;">
              <img src="../../../assets/icons/side-arrow.svg">
            </div>
          </div>
        </div>
      
      </div>
</div>


</template>

<script>
import $ from 'jquery';
import { useRoute, useRouter } from 'vue-router';
import ClientCatalogItemPage from "@/client/components/ClientCatalog/ClientCatalogItemPage";
import Spinner from "../../../components/Spinner";
const _ = require('lodash');
export default {
name: "Catalog",
  components:{
    ClientCatalogItemPage,
    Spinner
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    return { route, router };
  },
  data(){
    return{
      showSortDrop:false,
      sortBy:"ascendingName",
      searchStatus:false,
      searchText:"",
      spinner:true,
      catalog:[],
      listCategory:[],
      filtered: 'all',
      selectedCategory:"all",
      from:0,
      to:1000,
      showCategory:'All',
      currentPage:1,
      numberOfPagesArray:[],
    }
  },
  computed:{
    filteredList: function(){
      return  this.catalog
        // .filter(product => {
        //   if(this.filtered!=='all'){
        //     return product.category===this.filtered;
        //   }else{
        //     return product;
        //   }
        // })
        // .filter((product)=>{
        //   return product.price >= this.from && product.price <= this.to;
        // })
        // .filter((product)=>{
        //   return product.quantity > 0;
        // })
        // .filter((product)=>{
        //   return product.active === true;
        // })
    },
    currentCompanyCatalog() {
      return this.$route.params.bekon;
    },
    catalog_settings(){
      return this.$store.getters['Catalog/getCatalog_settings'];
    },
  },
  methods: {
    catalogSearch(){
      // this.selectedCategory = "all";
      this.currentPage = 1;
      this.getProducts();
      this.searchStatus = true;
    },
    setCategory(id){
      this.selectedCategory = id;
      this.currentPage = 1;
      localStorage.setItem('currentCategory', id);
      this.getProducts();
      
      // Обновляем URL при выборе категории
      this.router.push({ query: { ...this.route.query, categoryId: id } });
    },
    setPage(page){
      console.log(page)
      this.currentPage = page;
      localStorage.setItem('currentPage', page);
      this.getProducts();
    },
    displayFiltered(id){
        this.filtered = id
        this.listCategory.map(i=>{
          if(i._id == id){
            this.showCategory = i.name
          }
        })
        $('.showCategory').removeClass('active')

        $('.backdrop-filter').removeClass('active')
      $('body').css({'overflow':'auto'})
      },
    showFilterCategory(item){
      console.log("showFilterCategory");
      if(item==='category'){
        $('.showCategory').addClass('active')
      }
      else{
        $('.showFilter').addClass('active')
      }
      $('.backdrop-filter').addClass('active')
      $('body').css({'overflow':'hidden'})
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    },

    removeFilterCategory(item){
      console.log("removeFilterCategory");
      if(item === 'category'){
        $('.showCategory').removeClass('active')

      }
      else{
        $('.showFilter').removeClass('active')
      }
      $('body').css({'overflow':'auto'})
      $('.backdrop-filter').removeClass('active')
      const scrollY = document.body.style.top;
      document.body.style.top = '';
      document.body.style.position = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    },
    getRangeValues() {
      const slider = $("#range-slider").data("ionRangeSlider");
      this.from = slider.result.from;
      this.to = slider.result.to;
      console.log(`getRangeValues call ${slider.result.from} ${slider.result.to}`)
    },
    rangeSlider() {
      console.log("range slider call");
      $("#range-slider").ionRangeSlider({
        type: "double",
        min: 0,
        max: 9999,
        from: 0,
        to: 9999,
        prefix: this.catalog_settings.currency + " ",
        onChange: (data) => {
          console.log(`rangeSlider on change ${data.from}`)
          this.from = data.from;
          this.to = data.to
        }
      });
      $("#range-slider2").ionRangeSlider({
        type: "double",
        min: 0,
        max: 9999,
        from: 0,
        to: 9999,
        prefix: this.catalog_settings.currency + " ",
        onChange: (data) => {
          this.from = data.from;
          this.to = data.to
        }
      });
    },
    async  getProducts(){
      console.log("getProducts call");
      this.spinner = true;
      const options = {
        headers: {"x-client-url": this.currentCompanyCatalog},
        params: {
          "page":this.currentPage,
          "categoryId":this.selectedCategory,
          "min":this.from,
          "max":this.to,
          "searchText":this.searchText,
          "sortBy":this.sortBy,
        },
      }
       await this.axios.get(this.url('getClientProducts'),options)
         .then((response) => {
           this.catalog = response.data.objects;
           this.numberOfPagesArray = Array.from({length: response.data.pagesCount || 0}, (_, i) => i + 1)  ;
           this.spinner = false;
         })
    },
    async  getProductsForPrice(){
      //yes its a copy of a function
      console.log("getProductsForPrice_call");
      let that = this;
      const options = {
        headers: {"x-client-url": this.currentCompanyCatalog},
        params: {
          "page":this.currentPage,
          "categoryId":this.selectedCategory,
          "min":this.from,
          "max":this.to,
        },
      }
      await this.axios.get(this.url('getClientProducts'),options)
        .then((response) => {
          // this.catalog = response.data.objects;
          // this.numberOfPagesArray = Array.from({length: response.data.pagesCount || 0}, (_, i) => i + 1)  ;
          // this.spinner = false;
          this.from = response.data.minPrice;
          this.to = response.data.maxPrice;
          let instance = $("#range-slider").data("ionRangeSlider");
          instance.update({
            from: that.from,
            to:that.to,
            max:that.to,
          });
          let instance2 = $("#range-slider2").data("ionRangeSlider");
          instance2.update({
            from: that.from,
            to:that.to,
            max:that.to,
          });
        })
    },

    processCategories(categories) {
      const categoryMap = {};
      const processedCategories = [];

      // Создание словаря категорий для быстрого доступа
      categories.forEach(category => {
        categoryMap[category._id] = { ...category, level: 0, children: [] };
      });

      // Построение иерархии и определение уровня вложенности
      categories.forEach(category => {
        if (category.parent) {
          categoryMap[category.parent].children.push(categoryMap[category._id]);
          categoryMap[category._id].level = categoryMap[category.parent].level + 1;
        } else {
          processedCategories.push(categoryMap[category._id]);
        }
      });

      // Рекурсивная функция для флаттирования иерархии
      const flattenCategories = (categories) => {
        let flatList = [];
        categories.forEach(category => {
          flatList.push(category);
          if (category.children.length > 0) {
            flatList = flatList.concat(flattenCategories(category.children));
          }
        });
        return flatList;
      };

      return flattenCategories(processedCategories);
    },

    async getCategories() {
      const options = {
        headers: {"x-client-url": this.currentCompanyCatalog}
      }
      await this.axios.get(this.url('getClientCategories')+'?type=product', options)
        .then((res) => {
          const categories = res.data.objects;
          this.listCategory = this.processCategories(categories);
          this.listCategory.unshift({_id: 'all', name: 'All', level: 0});
        });
    },
    getByPrice: _.debounce(function() {
      console.log("getByPrice debounce call");
      this.getProducts();
    }, 500),
  },
  watch:{
    searchText:{
      handler: function (text) {
        if(text.length===0){
          this.searchStatus = false;
          this.getProducts();
        }
      },
    },

    from: {
      handler: function () {
        console.log("Watche from")
        this.getByPrice();
      },
    },
    to: {
      handler: function () {
        console.log("Watche to")
        this.getByPrice();
      },
    },
    
    '$route.query.categoryId': {
      immediate: true,
      handler(newCategoryId) {
        if (newCategoryId && newCategoryId !== this.selectedCategory) {
          this.setCategory(newCategoryId);
        }
      }
    }
  },
  created() {
    // Удаляем этот блок, так как теперь мы используем наблюдатель
    // this.selectedCategory = this.route.params.categoryId || null;
    // if (this.selectedCategory) {
    //   // this.loadCategoryProducts(this.selectedCategory);
    // }
  },
  mounted(){

    const savedPage = localStorage.getItem('currentPage');
    if (savedPage) {
        this.currentPage = parseInt(savedPage);  // Восстанавливаем страницу из localStorage
    }

    const savedCategory = localStorage.getItem('currentCategory');
    if (savedCategory) {
        this.selectedCategory = savedCategory;  // Восстанавливаем категорию из localStorage
    }
    //this.getProducts();

    console.log("MOUNTED START")
    this.getProductsForPrice()
    this.rangeSlider()
    // this.getRangeValues()
    this.getCategories()
    // this.getProducts()

}

}
</script>

<style scoped>
.show {
  position: absolute !important;
  bottom: 10px;
  width: 93% !important;
  right:3.5%;

}
.mobile-btns{
  display:none;
}
@-webkit-keyframes fadeIn {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);}
}

.backdrop-filter.active{
  display: block;
}
.catalogSearchClearIcon{
  position: absolute;
  top: 12px;
  right: 28px;
}

.catalogSort{
  width: 100%;
  height: 30px;
  outline: none;
  border-style: none;
  padding: 0 16px;
  background: url("../../../assets/img/select_arrow.svg") no-repeat;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background-position-x: calc(100% - 15px);
  background-position-y: 7px;
  color: #606877;
  font-size: 14px;
}
.catalogSort:focus-visible{
  border-style: none;
  outline: none;
}
.sortDropDown div{
  cursor: pointer;
}
.sortDropDown div:hover{
  color:#616cf5!important;
}

.sortDropDown{
  position: absolute;
  width: 100%;
  background: #F4F4F4;
  border-radius: 5px;
  top: 35px;
  right: 0;
  padding: 10px;
}

.selectWrapper{
  padding: 0 16px;
  color: #606877;
  font-size: 14px;
  height: 30px;
  border-radius: 20px;
  background: #F4F4F4;
  justify-content: center;
}

.catalogSearchInputIcon{
  position: absolute;
  top: 12px;
  left: 15px;
}
.catalogSearchInput{
  width: calc(100% - 13px);
  font-size: 18px;
  line-height: 24px;
  color: black;
  padding: 10px 120px 10px 40px;
  border-radius: 50px;
  border:1px solid #D3D3D3;
  margin-bottom: 5px;
}

.paginationItem{
  width: 28px;
  min-width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

.activePage{
  background-color: #616cf5;
  color: #fff;
  font-weight: bold;
}

.backdrop-filter{
  display: none;
  width: 100%;
  height:100%;
  position: fixed;
  top:0;
  left: 0;
  background: #000;
  opacity:0.5;
  z-index:1040;
}
.showCategory.active, .showFilter.active{
  display:block;
  -webkit-animation: fadeIn 0.3s;
  animation-fill-mode:forwards;
}
.showCategory,.showFilter{
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index:9999;
  top: 0;
  right:0;
  display: none;
  transition: .4s;
  background:#fff;
  padding:0 20px;

}

.filter-category{
  font-size: 20px;
  color:#222;
  font-weight: normal;
}
.catalog{
  color:#222222;
}
.category-key{
  color:#858585;
}
.category-value{
  color:#616cf5;
}
.save{
  width: 100%;
  margin-bottom: 15px;
  height: 45px;
  position: relative;
}
.list-icon{
  position: absolute;
  left:10px;
  background-size: contain;
}
.new-product, .app-buttons-item{
  height: 40px;
  width:100%;
}
.app-buttons-item{
  justify-content: center;
  color:#616cf5;
  position: relative;
}
.app-buttons-item img{
  position: absolute;
  left: 10px;
}
.catalog-left{
  padding-right:5px;
  /*position: sticky;*/
  top:40px;
  z-index: 1000;
  transition:0.3s;


}
.product-title{
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 20px;
}
.product-title img{
  margin-right: 10px;
}

.client-input{
  border: 1px solid #D3D3D3;
  border-radius: 3px;
  height: 30px;
  padding: 0 10px;
}
.label-client{
  color: #8C94A5;
}

.catalog-list{
  line-height: 1;
  box-sizing: border-box;
  list-style-type: none;
  border:none;
  height: 35px;
  color: #8C94A5;
  border-radius: 111.068px;
  padding:0 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}
.catalog-list:hover{
  background: #EBEEFF;
  color: #616CF5;
  cursor:pointer;
}
.catalog-list.active{
  background: #EBEEFF;
  border-radius: 111.068px;
  color: #616CF5;
}
@media(max-width:481px){
  .catalog-left{
    display:none;
  }
  .mobile-btns{
    display: block;
  }

}


</style>