<template>
  <footer>
    <div class="footer-container container d-flex justify-content-between align-items-center">
      <div class="footer-logo">
        <img class="web-catalog-logo" v-if="catalog_settings.logo && catalog_settings.logo !==''" :src="server+'/'+catalog_settings.logo">
        <span v-else>
          {{catalog_settings.name || 'Company Name'}}
        </span>
      </div>
      <div class="footer-logo-small">
        <img src="../../../assets/clients/loygift_new_logo.svg" alt="Small Logo">
      </div>
    </div>
    <div class="footer-content container">
      <div class="footer-column">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.</p>
      </div>
      <div class="footer-column" style="margin-right: 50px;">
        <h3>Page</h3>
        <p>Home</p>
        <p>About us</p>
        <p>Catalog</p>
        <p>Contact</p>
      </div>
      <div class="footer-column">
        <h3>Address</h3>
        <p>{{companyAddresses[0] ? companyAddresses[0].address : "Bishkek, Kyrgyzstan"}}</p>
      </div>
      <div class="footer-column">
        <h3>Contacts</h3>
        <p><img src="../../../assets/clients/Call.svg"><a href="/">{{companyAddresses[0] ? companyAddresses[0].phone : "+996771236478"}}</a></p>
        <p><img src="../../../assets/clients/Message.svg"><a href="/">{{catalog_settings.email || "example@gmail.com"}}</a></p>
      </div>
    </div>
    <hr>
    <div class="footer-bottom container d-flex justify-content-between align-items-center">
      <div class="footer-payment">
        <img src="../../../assets/clients/visa_logo.svg" alt="Visa">
        <img src="../../../assets/clients/mastercard_logo.svg" alt="Mastercard">
      </div>
      <div class="footer-poweredby">
        <span>powered by <strong>loygfit</strong></span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    catalog_settings() {
      return this.$store.getters['Catalog/getCatalog_settings'];
    },
    companyAddresses() {
      return this.$store.getters['Catalog/getCompanyAddresses'];
    },
  },
}
</script>

<style scoped>
footer {
  background: #F4F4F4;
  padding: 20px 0;
  margin-top: 100px;
}
.footer-container {
  padding: 20px 0;
}
.footer-logo img, .footer-logo-small img {
  max-height: 50px;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.footer-column {
  flex: 1;
  margin-right: 20px;
}
.footer-column:last-child {
  margin-right: 0;
}
.footer-column h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.footer-column p {
  margin-bottom: 10px;
  color: #484848;
}
.footer-column img {
  margin-right: 10px;
}
.footer-bottom {
  padding: 20px 0;
}
.footer-payment img {
  max-height: 30px;
  margin-right: 10px;
}
.footer-poweredby {
  color: #858585;
}
@media(max-width: 640px) {
  .footer-content {
    flex-direction: column;
    padding: 20px 20px; /* Добавлены боковые отступы */
  }
  .footer-container {
    padding: 20px 20px; /* Добавлены боковые отступы */
  }
  .footer-column {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .footer-logo, .footer-logo-small {
    text-align: center;
  }
  .footer-bottom {
    flex-direction: column;
    align-items: center;
    padding: 20px 15px; /* Добавлены боковые отступы */
  }
  .footer-payment {
    margin-bottom: 10px; /* Отступ снизу для разделения элементов */
  }
}
</style>