<template>
  <div>
  
   <div v-if="spinner"  style="height:65vh" class="d-flex align-items-center">
     <Spinner/>
   </div>
  
    <div v-else class="row pb-5">
      <div class="col-lg-5" style="padding-bottom: 70px">
  
        <div class="d-flex margin-10">
          <label class="switch d-flex" style="margin-top: 3px;">
            <input type="checkbox" v-model="card_on">
            <span class="slider round"></span>
          </label>
          <h2 class="catalog-title" >Activate CARD</h2>
        </div>
        <p class="catalog-description margin-30">Here you can activate your loyalty card</p>
  
        <h3 class="catalog-sub-title margin-10">Card name</h3>
        <div class="reload-code d-flex align-items-center">
          <input style="padding-bottom: 1px;padding-left: 0;" v-model="card_name">
        </div>

        <h3 class="catalog-sub-title margin-10" style="margin-top: 30px">Your URL for Wallet</h3>
        <p class="catalog-description mb-3">This link will be used to add loyalty card to the Wallet</p>
        <div class="reload-code d-flex align-items-center">
          <span style="color:#858585;">{{domainNameShop}}/</span>
          <input 
            style="color:#616CF5;padding-bottom: 1px;padding-left: 0;" 
            :value="catalogUrl + '/signin?c=1'" 
            readonly
          >
          <div @click="copyCardUrl" class="url-icon">
            <img src="../../assets/icons/urlIcon.svg">
          </div>
        </div>

        <h3 class="catalog-sub-title margin-20" style="margin-top: 20px">QR code for Wallet</h3>
        <!--     <h3 class="catalog-sub-title mb-3" style="text-overflow: ellipsis;overflow: hidden">{{catalogFullUrl}}</h3>-->
        <div class="d-flex align-items-center margin-50" >
          <img :src="qrcodePath" class="mr-2" style="width:90px; height:90px">
          <div>
            <p class="catalog-description mb-2">You can print this QR code so that <br> customers can quickly open their online card</p>
            <div>
              <span @click="manualGenerateQrcode" style="color:#616cf5;cursor: pointer; margin-right: 10px;">Generate</span>
              <img src="../../assets/icons/printer.svg" class="mr-2">
              <span @click="printQRCode" style="color:#616cf5;cursor: pointer">Print</span>
              
            </div>
          </div>
        </div>

        <h3 class="catalog-sub-title margin-10">Your AppStore app ID</h3>
        <div class="reload-code d-flex align-items-center">
          <input style="padding-bottom: 1px;padding-left: 0;" v-model="app_id">
        </div>

        <h3 class="catalog-sub-title margin-10">Change your field on card</h3>
        <p class="catalog-description mb-3">You can customize client information on card and field layout</p>
        
        <div class="row">
        <div class="col-md-6">
          <div ref="logicDropdownContainer1" class="sel-block-main mb-2">
            <div class="sel-block-main_modal" :class="{ 'show-category': isLogicVisible1 }">
              <div v-for="option in availableLogicOptions(0)" :key="option">
                <li class="catalog-list" :id="option">
                  <label class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                    <span class="name-category">
                      <span class="category-nm">{{ option }}</span>
                    </span>
                    <div class="custom-checkbox" style="opacity: 0;">
                    <input 
                      type="checkbox" 
                      :value="option" 
                      v-model="selectedLogic[0]"
                      @change="updateSelectedLogic(0, option)"
                    >
                    <span class="checkmark"></span>
                  </div>
                  </label>
                </li>
              </div>
            </div>
            <input 
              placeholder="Right top" 
              type="text" 
              class="selectcat-input" 
              readonly 
              :value="selectedLogic[0] || ''"
              @click="toggleLogicVisibility(1)"
            >
            <svg width="22" height="22" viewBox="0 0 18 18" fill="none" style="position: absolute; margin-left: -30px; margin-top: 7px" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_89_1499)">
                <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
              </g>
              <defs>
                <clipPath id="clip0_89_1499">
                  <rect width="25" height="25" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="col-md-6">
          <div ref="logicDropdownContainer2" class="sel-block-main mb-2">
            <div class="sel-block-main_modal" :class="{ 'show-category': isLogicVisible2 }">
          <div v-for="option in availableLogicOptions(1)" :key="option">
            <li class="catalog-list" :id="option">
              <label class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                <span class="name-category">
                  <span class="category-nm">{{ option }}</span>
                </span>
                <div class="custom-checkbox" style="opacity: 0;">
                    <input 
                      type="checkbox" 
                      :value="option" 
                      v-model="selectedLogic[1]"
                      @change="updateSelectedLogic(1, option)"
                    >
                    <span class="checkmark"></span>
                  </div>
              </label>
            </li>
          </div>
        </div>
        <input 
          placeholder="Left" 
          type="text" 
          class="selectcat-input" 
          readonly 
          :value="selectedLogic[1] || ''"
          @click="toggleLogicVisibility(2)"
        >
        <svg width="22" height="22" viewBox="0 0 18 18" fill="none" style="position: absolute; margin-left: -30px; margin-top: 7px" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_89_1499)">
            <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
          </g>
          <defs>
            <clipPath id="clip0_89_1499">
              <rect width="25" height="25" fill="white"/>
            </clipPath>
          </defs>
        </svg>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div ref="logicDropdownContainer3" class="sel-block-main mb-2">
            <div class="sel-block-main_modal" :class="{ 'show-category': isLogicVisible3 }">
          <div v-for="option in availableLogicOptions(2)" :key="option">
            <li class="catalog-list" :id="option">
              <label class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                <span class="name-category">
                  <span class="category-nm">{{ option }}</span>
                </span>
                <div class="custom-checkbox" style="opacity: 0;">
                    <input 
                      type="checkbox" 
                      :value="option" 
                      v-model="selectedLogic[2]"
                      @change="updateSelectedLogic(2, option)"
                    >
                    <span class="checkmark"></span>
                  </div>
              </label>
            </li>
          </div>
        </div>
        <input 
          placeholder="Center" 
          type="text" 
          class="selectcat-input" 
          readonly 
          :value="selectedLogic[2] || ''"
          @click="toggleLogicVisibility(3)"
        >
        <svg width="22" height="22" viewBox="0 0 18 18" fill="none" style="position: absolute; margin-left: -30px; margin-top: 7px" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_89_1499)">
            <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
          </g>
          <defs>
            <clipPath id="clip0_89_1499">
              <rect width="25" height="25" fill="white"/>
            </clipPath>
          </defs>
        </svg>
          </div>
        </div>
        <div class="col-md-6">
          <div ref="logicDropdownContainer4" class="sel-block-main mb-2">
            <div class="sel-block-main_modal" :class="{ 'show-category': isLogicVisible4 }">
          <div v-for="option in availableLogicOptions(3)" :key="option">
            <li class="catalog-list" :id="option">
              <label class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                <span class="name-category">
                  <span class="category-nm">{{ option }}</span>
                </span>
                <div class="custom-checkbox" style="opacity: 0;">
                    <input 
                      type="checkbox" 
                      :value="option" 
                      v-model="selectedLogic[3]"
                      @change="updateSelectedLogic(3, option)"
                    >
                    <span class="checkmark"></span>
                  </div>
              </label>
            </li>
          </div>
        </div>
        <input 
          placeholder="Right" 
          type="text" 
          class="selectcat-input" 
          readonly 
          :value="selectedLogic[3] || ''"
          @click="toggleLogicVisibility(4)"
        >
        <svg width="22" height="22" viewBox="0 0 18 18" fill="none" style="position: absolute; margin-left: -30px; margin-top: 7px" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_89_1499)">
            <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
          </g>
          <defs>
            <clipPath id="clip0_89_1499">
              <rect width="25" height="25" fill="white"/>
            </clipPath>
          </defs>
        </svg>
          </div>
        </div>
      </div>
      <div class="logic-button-row">
        <button @click="resetLogic" class="reset-button">Reset</button>
      </div>

        <!-- Модальное окно для выбора логики -->
        <div v-if="showLogicModal" class="logic-modal">
          <div class="logic-modal-content">
            <span class="close" @click="showLogicModal = false">&times;</span>
            <h3>Choose logic</h3> 
            <div class="logic-options">
              <div 
                v-for="option in logicOptions" 
                :key="option"
                :class="['logic-option', { 'selected': selectedLogic.includes(option) }]"
                @click="toggleLogicOption(option)"
              >
                {{ option }}
              </div>
            </div>
          </div>
        </div>

        <h3 class="catalog-sub-title margin-10">Change your field in registration \ login</h3>
        <p class="catalog-description mb-3">You can edit the fields filled in during registration and login</p>
        
        <div class="row">
          <div class="col-md-6">
            <h3 class="catalog-sub-title margin-10">Registration</h3>

            <div class="d-flex align-items-center" style="padding-bottom: 50px;">
              <div ref="cashbackDropdownContainer" style="margin-right: 10px;">
                <div class="sel-block-main">
                  <div class="sel-block-main_modal" :class="{ 'show-category': isCashbackCategoryVisible }">
                  <div v-for="(category, index) in auth_fields" :key="category.id">
                    <li v-if="category.name !== 'all'" class="catalog-list" :id="category.name" :ref="'cashbackMenu' + index">
                      <label class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                        <span class="name-category">
                          <span class="category-nm">{{ category.name }}</span>
                        </span>
                        <div class="custom-checkbox">
                          <input 
                            type="checkbox" 
                            :value="category.id" 
                            v-model="selectedCashbackCategories"
                            @change="updateSelectedCategories"
                          >
                          <span class="checkmark"></span>
                        </div>
                      </label>
                    </li>
                  </div>
                </div>
                  <input 
                    placeholder="Change field" 
                    type="text" 
                    class="selectcat-input" 
                    readonly 
                    @click="toggleCashbackCategoryVisibility"
                  >
                  <svg width="22" height="22" viewBox="0 0 18 18" fill="none" style="position: absolute; margin-left: -30px; margin-top: 7px" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_89_1499)">
                      <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_89_1499">
                        <rect width="25" height="25" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <h3 class="catalog-sub-title margin-10">Login</h3>

            <div class="d-flex align-items-center" style="padding-bottom: 50px;">
              <div ref="cashbackDropdownContainer" style="margin-right: 10px;">
                <div class="sel-block-main">
                  <div class="sel-block-main_modal" :class="{ 'show-category': isLoginVisible }">
                  <div v-for="(category, index) in login_fields" :key="category.id">
                    <li v-if="category.name !== 'all'" class="catalog-list" :id="category.name" :ref="'cashbackMenu' + index">
                      <label class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                        <span class="name-category">
                          <span class="category-nm">{{ category.name }}</span>
                        </span>
                        <div class="custom-checkbox">
                          <input 
                            type="checkbox" 
                            :value="category.id" 
                            v-model="selectedLogin"
                            @change="updateLogin"
                          >
                          <span class="checkmark"></span>
                        </div>
                      </label>
                    </li>
                  </div>
                </div>
                  <input 
                    placeholder="Change field" 
                    type="text" 
                    class="selectcat-input" 
                    readonly 
                    @click="toggleLoginVisibility"
                  >
                  <svg width="22" height="22" viewBox="0 0 18 18" fill="none" style="position: absolute; margin-left: -30px; margin-top: 7px" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_89_1499)">
                      <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_89_1499">
                        <rect width="25" height="25" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>


        
        <button type="button" @click="saveCatalogSettings" class="save mb-3" >Save</button>
      </div>
  
      <!------------------Right Side-------------------------------------   -->
  
      <div class="col-lg-5">
  
        <div class="d-flex margin-10">
          <label class="switch d-flex" style="margin-top: 3px;">
            <input type="checkbox" v-model="card_design_on">
            <span class="slider round"></span>
          </label>
          <h2 class="catalog-title" >Design card</h2>
        </div>
        <p class="catalog-description margin-30">Here you can activate and customize your loyalty card design </p>
  
        <div class="margin-30">
          <h3 class="catalog-sub-title margin-10">Add logo</h3>
          <p class="catalog-description margin-30">You can upload JPG or PNG photos, the size is not more than 3 MB.</p>
          <div class="profile-img ">
            <!--         <img class="profile-logo" :src="previewImage" >-->
            <img class="profile-logo" :src="logoPath">
            <input type="file" id="logo_file" @change="uploadImage($event,'logo_card')">
            <label class="addPhoto" for="logo_file"><img src="../../assets/icons/addBtn.svg"></label>
          </div>
        </div>

        <div class="margin-30">
          <h3 class="catalog-sub-title margin-10">Add icon</h3>
          <p class="catalog-description margin-30">You can upload JPG or PNG photos, the size is not more than 3 MB.</p>
          <div class="profile-img ">
            <!--         <img class="profile-logo" :src="previewImage" >-->
            <img class="profile-logo" :src="iconPath">
            <input type="file" id="icon_file" @change="uploadImage($event,'icon_card')">
            <label class="addPhoto" for="icon_file"><img src="../../assets/icons/addBtn.svg"></label>
          </div>
        </div>

        <div class="row margin-10">
        <div class="col-md-6">
          <h3 class="catalog-sub-title mb-3">Color background</h3>
          <div class="d-flex">
            <div :style="{ background: backgroundColor.value.hex }" class="dropdown dropMenu color-picker-button" style="size: 20px; padding: 0; left: 22px;
                                                                                              position: absolute;
                                                                                              border-radius: 5px;
                                                                                              margin-top: 8px;
                                                                                              border: 2px solid gray;">
              <div class="dropdown-toggle" id="dropdownMenuBackground" data-toggle="dropdown" aria-haspopup="true" style="size: 20px; padding: 0"
                aria-expanded="false">
                <div :style="{height: '20px', width: '20px'}"></div>
              </div>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuBackground">
                <div :style="{background: backgroundColor.value.hex}">
                  <Sketch 
                    v-model="backgroundColor.value" 
                    @input="()=>{console.log('change background color')}"
                  />
                </div>
              </div>
            </div>
            <input v-model="backgroundColor.value.hex" class="form-input cashback-input" placeholder="Choose background color" style="padding-left: 42px">
          </div>
        </div>

        <div class="col-md-6">
          <h3 class="catalog-sub-title mb-3">Color text</h3>
          <div class="d-flex">
            <div :style="{ background: textColor.value.hex }" class="dropdown dropMenu color-picker-button" style="size: 20px; padding: 0; left: 22px;
                                                                                              position: absolute;
                                                                                              border-radius: 5px;
                                                                                              margin-top: 8px;
                                                                                              border: 2px solid gray;">
              <div class="dropdown-toggle" id="dropdownMenuText" data-toggle="dropdown" aria-haspopup="true" style="size: 20px; padding: 0"
                aria-expanded="false">
                <div :style="{height: '20px', width: '20px'}"></div>
              </div>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuText">
                <div :style="{background: textColor.value.hex}">
                  <Sketch 
                    v-model="textColor.value" 
                    @input="()=>{console.log('change text color')}"
                  />
                </div>
              </div>
            </div>
            <input v-model="textColor.value.hex" class="form-input cashback-input" placeholder="Choose text color" style="padding-left: 42px">
          </div>
        </div>
      </div>

        <h3 class="catalog-sub-title margin-10">Add banner</h3>
        <p class="catalog-description margin-30">You can upload JPG or PNG photos, the size is not more than 3 MB.</p>
  
        <div class="profile-img big-profile-img margin-30">
          <img :class="{now_imgs:!banner}" :src="bannerPath">
          <input type="file" id="big-img" @change="uploadImage($event,'banner_card')">
          <label class="addPhoto big-addPhoto"  for="big-img"><img src="../../assets/icons/addBtn.svg"></label>
        </div>
  
        <h3 class="catalog-sub-title margin-10">Other info</h3>
        <p class="catalog-description margin-30">You can add any information on backside cart wallet</p>
        <QuillEditor 
          ref="myQuillEditor"
          contentType="html"
          v-model:content="description1" 
          :options="editorOption"
          class="general-area definition-area margin-30 pt-2"
          style="max-height: 250px;"
        />

        <!-- <p class="mt-1">To save the changes, you should definitely clear the old text.</p> -->
        <!-- <div class="logic-button-row">
          <button @click="clearDescription" class="reset-button">Reset editor</button>
        </div> -->
        
        

        
        
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import { Sketch } from '@ckpack/vue-color';
  import $ from "jquery";
  
  import { QuillEditor } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css'


  // import { ColorPicker } from 'vue-color-kit'
  import Spinner from "../Spinner";
  export default {
    name: "CatalogSettings",
    components:{
      Spinner,
      Sketch,
      QuillEditor
    },
    data(){
      return{

        app_id: "",

        card_on: false,
        card_name: '',

        login_fields: [
          {name: "Phone", check: true, id: "phone"},
          {name: "Email", check: true, id: "email"}, 
        ],
        isLoginVisible: false,
        selectedLogin: [],

        auth_fields: [
          {name: "Name", check: true, id: "name"},
          {name: "Phone", check: true, id: "phone"},
          {name: "Email", check: true, id: "email"}, 
          {name: "Password", check: true, id: "password"},
        ],
        isCashbackCategoryVisible: false,
        selectedCashbackCategories: [],

        showLogicModal: false,
        
        isLogicVisible1: false,
        isLogicVisible2: false,
        isLogicVisible3: false,
        isLogicVisible4: false,
        logicOptions: ['Discount', 'Cashback', 'Points', 'Name'],
        selectedLogic: ['', '', '', ''],

        card_design_on: false,

        logo:'',
        banner:'',
        icon:'',

        backgroundColor: {
          name: '',
          value: { hex: '#6c757d' }
        },
        textColor: {
          name: '',
          value: { hex: '#000000' }
        },

        description:'',
        description1: "",

        editorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
          ]
        }
      },

        spinner:true,
        company:'',
        id:'',
        catalog_status:false,
        catalogUrl:'',
        catalog_mode_status:false,
        news_status:false,
        news_title:'',
        news_description:'',
        filters_n_category_status:false,
        food_mode_status:false,
        color:'#616cf5',
        whatsapp:'dd',
        telegram:'',
        facebook:'',
        instagram:'',
        website:'',
        seen: false,
        productCustomField1: { name: '', value: ''},
        productCustomField2:  { name: '', value: ''},
        productCustomFields: false,
        orderStatusesPass: false,
        category_picture: false,
        productCustomColors:{
          required: false,
          values: ['black'],
          names: ['']
        },
        orderStatuses: ["Accept", "In Progress", "Done"],
        currentNewColor: {
          name: '',
          value: { hex: '#000' }
        },
        
        // previewImage:require('../../assets/icons/profile-img.svg'),
      }
    },
    
    computed:{

      // availableLogicOptions() {
      //   return this.logicOptions.filter(option => !this.selectedLogic.includes(option));
      // },

      selectedCategoriesText() {
        if (this.selectedCashbackCategories.length === 0) {
          return 'Select categories';
        }
        return this.selectedCashbackCategories.join(', ');
      },

      logicInput() {
        return this.selectedLogic.join(' + ');
      },

      catalogFullUrl(){
        return window.location.host+'/'+this.catalogUrl;
      },
      cardFullUrl(){
        return window.location.host+'/'+this.catalogUrl + '/signin?c=1';
      },
      domainNameShop(){
        return window.location.host;
      },
      qrcodePath(){
        if(this.company !=="" && this.id !==''){
          try {
            // return require("../../../images/"+this.company+'/qr/code.png');
            return this.server+"/images/"+this.company+'/qr_card/code.png';
            // eslint-disable-next-line no-empty
          }catch (e){
            console.log(e,"ddddddddddddd");
          }
        }
        return require("../../assets/icons/profile-img.svg");
      },
      logoPath(){
        if(this.logo !=="" && this.id !==''){
          try {
            // return require("../../../"+this.logo);
            return this.server+'/'+ this.logo;
            // eslint-disable-next-line no-empty
          }catch (e){
            console.log(e,"ddddddddddddddddddddddddddddddddd========================")
          }
        }
        return require("../../assets/icons/profile-img.svg");
      },
      iconPath(){
        if(this.icon !=="" && this.id !==''){
          try {
            return this.server+'/'+ this.icon;
            // eslint-disable-next-line no-empty
          }catch (e){
            console.log(e,"ddddddddddddddddddddddddddddddddd========================")
          }
        }
        return require("../../assets/icons/profile-img.svg");
      },
      bannerPath(){
        if(this.banner !=="" && this.id !==''){
          try {
            return this.server +'/'+ this.banner;
            // eslint-disable-next-line no-empty
          }catch (e){
            console.log(e);
          }
        }
        return require("../../assets/icons/setting-icon/no-img.svg");
      },
      server(){
        return this.$server;
      },
    },
    methods:{

      async checkQrCodeExists() {
        if (!this.qrcodePath || this.qrcodePath === require("../../assets/icons/profile-img.svg")) {
          return false;
        }

        try {
          const response = await fetch(this.qrcodePath, { method: 'GET' });
          if (!response.ok) {
            return false;
          }
          
          // Проверяем тип содержимого
          const contentType = response.headers.get('content-type');
          if (!contentType || !contentType.includes('image')) {
            return false;
          }

          // Проверяем размер изображения
          const blob = await response.blob();
          return blob.size > 0;

        } catch (error) {
          console.error('Error checking QR code existence:', error);
          return false;
        }
      },

      clearDescription() {
        this.description1 = "";
        this.$refs.myQuillEditor.setText('')
        this.$forceUpdate();
        console.log("reset desc1", this.description1)
      },

      onEditorChange(content) {
        this.description1 = content;
        console.log('Editor content changed:', content);
      },

      resetLogic() {
        this.selectedLogic = ['', '', '', ''];
      },

      availableLogicOptions(index) {
        return this.logicOptions.filter(option => 
          option === this.selectedLogic[index] || 
          !this.selectedLogic.includes(option)
        );
      },

      updateSelectedLogic(index, option) {
        if (this.selectedLogic[index] === option) {
          this.selectedLogic[index] = '';
        } else {
          // Если опция уже выбрана в другом дропдауне, удалим ее оттуда
          const existingIndex = this.selectedLogic.indexOf(option);
          if (existingIndex !== -1) {
            this.selectedLogic[existingIndex] = '';
          }
          this.selectedLogic[index] = option;
        }

        if (index === 0) {
          this.isLogicVisible1 = false;
        } else if (index === 1) {
          this.isLogicVisible2 = false;
        } else if (index === 2) {
          this.isLogicVisible3 = false;
        } else {
          this.isLogicVisible4 = false;
        }

        console.log(this.selectedLogic)
        this.$forceUpdate();
         // Принудительно обновляем представление
      },

      toggleLogicVisibility(index) {
        this[`isLogicVisible${index}`] = !this[`isLogicVisible${index}`];
      },

      toggleCashbackCategoryVisibility() {
        this.isCashbackCategoryVisible = !this.isCashbackCategoryVisible;
      },
      updateSelectedCategories() {
        // Здесь вы можете выполнить любые дополнительные действия при изменении выбора
        console.log('Selected categories:', this.selectedCashbackCategories);
      },

      toggleLoginVisibility() {
        this.isLoginVisible = !this.isLoginVisible;
      },
      updateLogin() {
        // Здесь вы можете выполнить любые дополнительные действия при изменении выбора
        console.log('Selected login params:', this.selectedLogin);
      },

      toggleLogicOption(option) {
        const index = this.selectedLogic.indexOf(option);
        if (index > -1) {
          this.selectedLogic.splice(index, 1);
        } else {
          this.selectedLogic.push(option);
        }
      },
      
      addColor() {
      if (this.currentNewColor.name.length === 0) {
        this.$warningAlert('Enter color name');
        return        
      }
      this.productCustomColors.values.push(this.currentNewColor);
      this.currentNewColor = {
        name: '',
        value: { hex: '#000'} 
      }
    },
      changePickerColor() {
          $('.siding-bar').removeClass('active')
        },

      removeVal(field, index){
        this[field].values.splice(index, 1)
      },
      addNewVal(field){
        this[field].values.unshift('');
      },
      addNewColorName() {
        this.productCustomColors.names.unshift('');
      },
      async uploadImage(e,type){
        let that = this;
        const image = e.target.files[0];
        if(image.name.match(/\.(jpg|jpeg|png|gif)$/)){
          let size = image.size;
          if(size>3000000){
            that.$warningAlert('Max size 3MB');
            return ;
          }
          const reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onload = e =>{
            //check
            let im = new Image;
            im.src = e.target.result;
            im.onload = function (){
              console.log(im.width,im.height);
              // if(type==="banner" && (im.width<1200 || im.width<320)){
              //   that.$warningAlert('Min resolution 1200*320px');
              // }else{
              //   // this.previewImage = e.target.result;
              //   that.saveFile(type,e.target.result);
              // }
              that.saveFile(type,e.target.result);
            }
          };
  
        }else{
          console.log('wrong type',+image.type)
        }
      },
      copyCatalogUrl(){
        let that = this;
        let textArea = document.createElement("textarea");
        textArea.value = this.catalogFullUrl;
        textArea.style.opacity = "0";
        // document.body.appendChild(textArea);
        document.body.prepend(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          that.$successAlert('Copied');
        } catch (err) {
          that.$warningAlert('Unable to copy value , error : ' + err.message);
        }
  
        document.body.removeChild(textArea);
      },
      copyCardUrl(){
        let that = this;
        let fullUrl = this.catalogFullUrl + '/signin?c=1';
        let textArea = document.createElement("textarea");
        textArea.value = fullUrl;
        textArea.style.opacity = "0";
        document.body.prepend(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          that.$successAlert('Copied');
        } catch (err) {
          that.$warningAlert('Unable to copy value , error : ' + err.message);
        }
        document.body.removeChild(textArea);
      },
      updateCatalogUrl(){
        let that=this;
        let url = this.url('updateSettings');
        this.axios.put(url, {
          catalogUrl:this.catalogUrl
        }).then(function (response) {
          console.log(response);
        }).catch(function(error){
            if(error.response.data && !error.response.data.errors){
                  that.$warningAlert(error.response.data.msg)
              }
          if (error.response) {
            console.log(error.message)
          }
        });
      },
      generateQrcode() {
        return new Promise((resolve, reject) => {
          let that = this;
          if (!this.catalogUrl) {
            reject('Catalog URL is not set');
            return;
          }

          let list_of_router_js_routes = this.$router.options.routes;
          let found = 0;
          list_of_router_js_routes.forEach(function (component){
            if(component.path.search(that.catalogUrl)!==-1){
              found++;
            }
          })

          console.log(that.catalogUrl)
          if(found>0){
            reject('Reserved route');
            return;
          }

          let url = this.url('generateQrCodeFile');
          this.axios.put(url, {
            catalogUrl: this.cardFullUrl,
            catalog: this.catalogUrl,
            settings_id: this.id,
            type: "card"
          }).then(function (response) {
            let data = response.data;
            if(data.validation == 1){
              resolve(data.msg);
            } else {
              reject(data.msg);
            }
          }).catch(function(error){
            if (error.response) {
              if(error.response.data && !error.response.data.errors){
                reject(error.response.data.msg);
              }
            }
          });
        });
      },

      async manualGenerateQrcode() {
        try {
          await this.generateQrcode();
          this.$successAlert('QR code generated successfully');
        } catch (error) {
          this.$warningAlert(error);
        }
      },

      async autoGenerateQrCode() {
        const qrCodeExists = await this.checkQrCodeExists();
        console.log("this.catalogUrl, !qrCodeExists", this.catalogUrl, !qrCodeExists)
        if (this.catalogUrl && !qrCodeExists) {
          try {
            await this.generateQrcode();
            // this.$successAlert('QR code generated successfully');
          } catch (error) {
            console.error('Error generating QR code:', error);
            // Не показываем предупреждение пользователю при автоматической генерации
          }
        }
      },

      printQRCode() {
        let win = window.open('about:blank', "_new");
        win.document.open();
        win.document.write([
          '<html>',
          '   <head>',
          '   </head>',
          '   <body onload="window.print()" onafterprint="window.close()">',
          '       <img width="100%" src="' + this.qrcodePath + '"/>',
          '   </body>',
          '</html>'
        ].join(''));
        win.document.close();
      },
      saveFile(type, file) {
        console.log("SAVING FILE");
        let that = this;
        let url = this.url('saveSettingsFile');
        let formData = new FormData();
        formData.append('icon', file);
        formData.append('logo', file);
        formData.append('banner', file);
        formData.append('id', this.id);
        formData.append('type', type);
        this.axios.put(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function (response) {
          console.log(response);
          that.$successAlert('Updated');
          
          // Добавляем эту часть
          if (response.data && response.data.url) {
            const fullUrl = response.data.url;
            if (type === 'logo_card') {
              that.logo = fullUrl;
            } else if (type === 'banner_card') {
              that.banner = fullUrl;
            } else if (type === 'icon_card') {
              that.icon = fullUrl;
            }
          }
        }).catch(function(error) {
          if (error.response) {
            if (error.response.data && !error.response.data.errors) {
              that.$warningAlert(error.response.data.msg)
            }
          }
        });
      },
      onColorPick (color) {
        this.color = color;
      },
      saveCatalogSettings(){
        let that=this;
        let url = this.url('updateSettings');
        console.log("desc notepad", this.description1)
        this.axios.put(url, {
          catalogUrl: this.catalogUrl,
          card: {
            on: this.card_on,
            app_id: this.app_id,
            name: this.card_name,
            logic: this.selectedLogic,
            auth_fields: this.selectedCashbackCategories,
            login_fields: this.selectedLogin,
            design_on: this.card_design_on,
            background_color: this.backgroundColor,
            text_color: this.textColor,
            description: this.description1,
            logo: this.logo || "",
            banner: this.banner || "",
            icon: this.icon || "",
          }
        }).then(function (response) {
          console.log(response);
          that.$successAlert('Updated');
        }).catch(function(error){
          if (error.response) {
              if(error.response.data && !error.response.data.errors){
                  that.$warningAlert(error.response.data.msg)
              }else{
                  that.$warningAlert('Something went wrong');
              }
            
            // console.log(error.response.status);
            // console.log(error.response.headers);
            // that.displayMessages(Object.values(error.response.data.errors),"Errors");
          }
        });
      },
      getDaysLeft(selectedDate){
      let today = this.$moment();
      let start = this.$moment(selectedDate).startOf('day');
      let end = this.$moment(today).startOf('day');
      return start.diff(end, 'days',true);
    },
    checkAccess1(){
      this.user_info = JSON.parse(localStorage.getItem('user'))
      let days_left = this.getDaysLeft(this.user_info.activeBefore)
      console.log("days_left", days_left)
      if (days_left <= 0) {
        this.$router.push({name: 'Price'})
    }
    }
    },
    beforeCreate(){
      let that = this;
      this.axios
        .get(this.url('getSettings'))
        .then(async function (response){

          if (response.data.object.card) {

            if (response.data.object.custom_field_0 && response.data.object.custom_field_0.required && response.data.object.custom_field_0.fieldName !== "") {
              that.auth_fields.push({name: response.data.object.custom_field_0.fieldName, check: true, id: "custom_field_0"},)
            }
            if (response.data.object.custom_field_1 && response.data.object.custom_field_1.required && response.data.object.custom_field_1.fieldName !== "") {
              that.auth_fields.push({name: response.data.object.custom_field_1.fieldName, check: true, id: "custom_field_1"},)
            }
            if (response.data.object.custom_field_2 && response.data.object.custom_field_2.required && response.data.object.custom_field_2.fieldName !== "") {
              that.auth_fields.push({name: response.data.object.custom_field_2.fieldName, check: true, id: "custom_field_2"},)
            }

            let settings = response.data.object.card;
            that.card_on = settings.on || false;
            that.app_id = settings.app_id || "";
            that.card_name = settings.name || '';
            that.selectedLogic = settings.logic || [];
            that.selectedCashbackCategories = settings.auth_fields || [];
            that.selectedLogin = settings.login_fields || [];
            that.card_design_on = settings.design_on || false;
            that.backgroundColor = settings.background_color || '';
            that.textColor = settings.text_color || '';
            that.description1 = settings.description || '';

            that.logo = settings.logo || '';
            that.banner = settings.banner || '';
            that.icon = settings.icon || '';
          }

          that.id = response.data.object._id || '';
          that.company = response.data.company || '';
          that.catalogUrl = response.data.object.catalogUrl || '';

          if (!response.data.object.catalogUrl || response.data.object.catalogUrl === "") {
            that.catalogUrl = Math.random().toString(36).slice(2);
          }

          console.log("auto generate qr - mounted")
          await that.autoGenerateQrCode();

          that.spinner = false;
        })
        
    },
    
    mounted() {

      console.log("auto generate qr - mounted")
      //this.autoGenerateQrCode();
      this.checkAccess1();

      
    }
  }
  </script>
  
  <style scoped>
  /* @import url('vue-accessible-color-picker/styles'); */


  .logic-button-row {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 30px;
}

.reset-button {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 120px;
  height: 37px;
}

.reset-button:hover {
  background-color: #ff7875;
}



  .ql-editor {
    min-height: 100px;
    max-height: 300px;
  }

  .logic-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.logic-modal-content {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 300px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.logic-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.logic-option {
  background-color: #616CF5;
  color: white;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logic-option.selected {
  background-color: #aaa;
}


  .discount-btn{
    height: 45px;
    flex: 0 0 45px;
    border-radius:5px;
    background: none;
    border: none;
  }
  .working-label{
    color:#858585;
  }
  .phone-number{
    color: #616CF5;
    font-size: 16px;
  }
  
  .social-btns{
    border: 1px solid #D3D3D3;
    border-radius: 5px;
    width: 100%;
    height: 45px;
    background: none;
    color:#8C94A5;
    text-align: left;
    padding-left:20px;
    margin-bottom: 10px;
  }
  .color-box{
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 10px;
  }
  .color-input{
    width: 80%;
    height: 30px;
    border:none;
  }
  .color-picker{
    height: 30px;
    width: 30px;
    /*background: #616cf5;*/
    border-radius:5px;
  }
  
  .save{
    width: 120px;
  }
  .now_imgs{
    object-fit: contain !important;
    padding:10px 0;
  }
  .catalog-title{
    font-size: 20px;
    font-weight: 600;
  }
  .catalog-description{
    font-size: 14px;
    color:#b0b0b0;
    margin-bottom: 0;
  }
  .catalog-sub-title{
    font-size: 16px;
    font-weight: normal;
  }
  .margin-10{
    margin-bottom: 10px;
  }
  .margin-30{
    margin-bottom: 30px;
  }
  .margin-20{
    margin-bottom: 20px;
  }
  .margin-50{
    margin-bottom: 50px;
  }
  .big-profile-img{
    width: 100% !important;
    height:150px;
    border:1px solid #d3d3d3;
    display: flex;
    justify-content: center;
    border-radius: 10px;
  
  
  }
  .big-profile-img img{
    border-radius: 10px;
  }
  .big-addPhoto{
    right: -18px !important;
    bottom: -23px !important;
  }
  .definition-area{
    height: 100px;
  }
  .accent-icon{
    margin-top: 5px;
    margin-left: 10px;
    width: 38px;
    height: 35px;
    background: #616cf5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .url-icon{
    width: 38px;
    height: 34px;
    background: #616cf5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .url-icon img{
    width: 18px;
    height: 18px;
  }
  .cashback-input{
    width: 100%;
    margin-bottom: 20px;
  }
  /*.show-pswrd{*/
  /*  width: 20px;*/
  /*  height: 20px;*/
  /*}*/
  .profile-img{
    width: 100px;
    height: 100px;
    position: relative;
    border:1px solid #d3d3d3;
    border-radius:10px;
  }
  .profile-img img{
    width: 100%;
    height: 100%;
    object-fit:cover;
  }
  .profile-logo{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
  
  }
  .profile-img input{
    display: none;
  }
  .addPhoto{
    position: absolute;
    bottom: -20px;
    right: -18px;
  }
  .picker_close_btn img{
    position: absolute;
    z-index: 1;
    right: -7px;
    top: -16px;
    transform: rotate(
        45deg
    );
  }




  
  .edit-header-item{
    margin-right: 40px;
  }
  .edit-header-right{
    color:#616cf5;
    font-size: 16px;
    font-weight: normal;
    cursor:pointer;
    white-space: nowrap;
  }
  .edit-header-right img{
    margin-right: 10px;
  }
  
  
  .selectcat-input {
    max-width: max-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    background: rgba(248, 249, 251, 1);
    max-height: 35px;
    width: 100%;
    border-radius: 5px;
    height: 45px;
    padding-left: 10px;
    padding-right: 30px;
  }

  .selectcat-input div{
    width: 50%;
  }

  
  .sel-block-main{
    position: relative;
  }
  .sel-block-main input{
    cursor: pointer;
  }
  .sel-block-main .sel-block-main_modal{
    position: absolute;
    top: 120%;
    background: white;
    z-index: 1;
    width: 100%;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 2px 11px 35px 0px rgba(0, 0, 0, 0.1);
    display: none;
    min-width: 120px;
    padding-bottom: 20px;
    max-height: 634px;
    overflow-y: auto;
  }
  .sel-block-main .sel-block-main_modal.show-category{
    display: block;
  }
  .sel-block-main .sel-block-main_modal div .catalog-list{
    list-style: none;
    cursor: pointer;
  }
  .sel-block-main .sel-block-main_modal div .catalog-list:hover{
    background: rgb(248, 249, 255);
    color: black;
  }
  .sel-block-main .sel-block-main_modal .catalog-list + div {
      margin-left: 0px;
  }
  .sel-block-main .sel-block-main_modal .catalog-list .category-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    padding: 0 16px;
    position: relative;
  }
  .sel-block-main .sel-block-main_modal .catalog-list .category-text::after{
      content: '';
      position: absolute;
      display: inline-block;
      height: 1px;
      background: rgba(235, 235, 235, 1);
      left: auto;
      bottom: 0;
      width: calc(100% - 32px);
      right: auto;
  }
  .sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow, .sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow path{
    transition: 0.3s ease;
  }
  .sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow:hover path{
    fill: black;
  }
  .sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow.arrow-down{
    transform: rotate(180deg);
  }
  .catalog-list .category-text .name-category{
    width: 100%;
    padding: 15px 0px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .category-nm {
    color: black;
    font-size: 16px;
    margin: 0;
    flex-grow: 0; /* Оставляем текст в его обычном размере */
    max-width: max-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .category-pr {
    color: black;
    border: none;
    text-align: center; /* Центрируем текст в инпуте */
    margin-left: auto; /* Сдвигаем input в правый край */
  }

  .selects {
    display: flex;
    align-items: center;
  }

  .sel-block-main {
    width: 200px; /* Установите подходящю ширину */
  }

  .catalog-list .category-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 16px;
  width: 100%;
}

.catalog-list .category-text .name-category {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.catalog-list .category-text .custom-checkbox {
  flex-shrink: 0;
  margin-left: 10px;
}

.custom-checkbox {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 13px;
  width: 13px;
  background-color: #eee;
  border-radius: 4px;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #616CF5;
}


.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
  </style>