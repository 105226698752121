<template>
  <div class="parent-modal">
    <div class="modal myModal fade" id="arrangement-category" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content category-content">
          <div class="modal-header category-header align-items-center">
            <h3 class="modal-title">Arrangement of categories</h3>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close">
              <span aria-hidden="true">
                <img src="../../../assets/icons/x.svg" alt="">
              </span>
            </button>
          </div>
          <div class="modal-body category-body">
            <!-- Ограничиваем высоту и добавляем прокрутку -->
            <div class="list-categories-container">
              <div class="list-categories" v-for="(category, categoryIndex) in listCategory" 
                :key="category._id" 
                :index="index + '_' + categoryIndex"
              >
              <div v-if="category.name !== 'Without category' && !category.parent" class="category-row">
                <p>{{ category.name }}</p>
                <input type="text" v-model="category.index">
              </div>
              </div>
            </div>
            <!-- Центрируем кнопку -->
            <div class="d-flex justify-content-center">
              <button @click="onSubmit" class="save">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import $ from 'jquery';
export default {
name: "ArrangementCategory",
  props:{
    listCategory:{
      type:Array,
      required:true
    },
    getCategories:{
      type:Function,
    },
    navigateDisplayParentCategories:{
      type:Array,
      required:true
    },
  },
  data(){
    return{
      new_category: {
        name: '',
        type: 'product',
        parent: ''
      },

      currentData: {},
      isCategoryOpen: {},
      isChildOpen: {},
      selectedCategory: null,
      isCategoryVisible: false,
      selectedCategoryInput:'',
      file: null,
      exampleFileUrl: '/example_import_products.xlsx',
    }
  },
  methods:{

    onSubmit(){
      
      let new_data = this.listCategory
        .filter(category => category.name !== 'Without category' && !category.parent) // Отфильтровываем ненужные категории
        .map(category => ({
          id: category._id,
          new_index: Number(category.index)  // Прямо получаем значения из модели данных
        }));

      const data = {
        categories: new_data
      }
      console.log(data);

      this.axios.post(this.url('updateCategoryIndexes'),data)
            .then(()=>{
              this.$successAlert('Category arrangement updated')
              this.getCategories()
            })
            .catch((error)=>{
              this.$warningAlert('Failed to update category arrangement')

              console.log("Error"+error)
            })

      this.$emit('refreshCategories')
      $('#arrangement-category').modal("hide")
    },
  },

  mounted(){
    // удалить из listCategory первый элемент
    console.log(this.listCategory)
  },

}
</script>

<style scoped>
.category-row {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.category-row p {
  font-size: 16px;
  margin: 0;
}

.category-row input {
  border-radius: 5px;
  width: 45px;
  border: none;
  background-color: #f0f0f0;
  text-align: center;
  padding: 2px;
  margin-left: auto;
}

/* Ограничиваем высоту списка категорий и добавляем прокрутку */
.list-categories-container {
  max-height: 30vh; /* Максимальная высота - 30% от высоты экрана */
  overflow-y: auto; /* Вертикальная прокрутка */
  margin-bottom: 20px;
}

.modal-select {
  font-size: 14px;
}

.category-content {
  border: 0;
  border-radius: 0;
  width: 562px;
}

.parent-modal {
  display: flex;
}

.category-body {
  padding: 0 50px;
  padding-bottom: 34px;
}

.category-header {
  padding: 34px 50px;
}

/* Центрируем кнопку Save */
.d-flex.justify-content-center {
  justify-content: center !important;
}


</style>